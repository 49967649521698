import { combineActions, handleActions } from 'redux-actions';
import {
  fetchHistoryAction,
  fetchHistoryDetailsAction,
  fetchHistoryChecklistsAction,
  createHistoryChecklistAction,
  editHistoryChecklistAction,
  deleteHistoryChecklistAction,
  fetchHistoryChecklistAction,
  updateHistoryChecklistAction,
} from './actions';

const initialState: History.Root = {
  historyCount: 0,
  historyItems: [],
  checklistsCount: 0,
  checklistsHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(fetchHistoryAction, fetchHistoryChecklistsAction) as any]: {
    next: (
      state: History.Root,
      action: Shared.ReduxAction<Partial<History.Root> & { skipStoreUpdate: true | undefined }>
    ): History.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchHistoryAction,
  fetchHistoryDetailsAction,
  fetchHistoryChecklistsAction,
  createHistoryChecklistAction,
  editHistoryChecklistAction,
  deleteHistoryChecklistAction,
  fetchHistoryChecklistAction,
  updateHistoryChecklistAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
