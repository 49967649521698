import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { taskMaterialsCountSelector, taskMaterialsSelector } from 'modules/tasks/selectors';
import { fetchMaterialsByTaskIDAction } from 'modules/tasks';
import { DataTable } from 'components/_common';
import { isNKTenant } from 'utils';
import { PaginationType, TableHeaders } from 'constants/index';

interface Props {
  id: number;
}

const TableTaskMaterials: React.FC<Props> = ({ id }) => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(taskMaterialsCountSelector);
  const items = useSelector(taskMaterialsSelector);
  const type = isNKTenant ? PaginationType.TASK_MATERIALS : PaginationType.TASKS_MATERIALS;

  const sendRequest = useCallback(() => dispatch(fetchMaterialsByTaskIDAction(id, type)), [id, type, dispatch]);

  const customHeaders = useMemo(() => {
    if (isNKTenant) return TableHeaders[type];
    return [
      ...TableHeaders[type]
        .filter(header => header.titleKey !== 'Actions')
        .map(header => ({ titleKey: header.titleKey })),
      { titleKey: 'Quantity' },
    ];
  }, [type]);

  return (
    <DataTable
      maxHeight="50vh"
      containerClassName="p-0 border-top-0"
      paginationType={isNKTenant ? PaginationType.TASK_MATERIALS : PaginationType.TASKS_MATERIALS}
      totalAmount={count}
      sendRequest={sendRequest}
      customHeaders={customHeaders}
    >
      {items?.map((item: Tasks.Material) => (
        <tr key={item.id}>
          {isNKTenant ? (
            <>
              <td>{item.date ? dateFormat(item.date) : '-'}</td>
              <td>{numberFormat(item.quantity, { fallback: '-' })}</td>
              <td className="text-right">
                {item.currency ? `${item.currency} ` : ''}
                {numberFormat(item.cost, { minimumFractionDigits: 2, fallback: '-' })}
              </td>
              <td>{item.type || '-'}</td>
              <td>{item.unit || '-'}</td>
              <td>{item.description || '-'}</td>
            </>
          ) : (
            <>
              <td>{item.name}</td>
              <td>{item.code}</td>
              <td>{item.unit}</td>
              <td>{item.quantity}</td>
            </>
          )}
        </tr>
      ))}
    </DataTable>
  );
};

export default TableTaskMaterials;
