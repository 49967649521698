import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  componentPricesCountSelector,
  componentPricesSelector,
  allTableComponentPricesFetchedSelector,
} from 'modules/setup/selectors';
import { fetchComponentPricesAction, deleteComponentPriceAssetAction } from 'modules/setup';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { portfolioIdSelector, selectedCurrencySelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { Currencies, CurrenciesRatesMap, GriZoneNamesLocalesMap, PaginationType } from 'constants/index';
import { IconEdit, IconDuplicate, IconDelete } from '@utiligize/shared/resources';

const TableComponentPrices: React.FC<{ inContentContainer?: boolean }> = ({ inContentContainer = false }) => {
  const { getIntl } = useLocale();
  const { numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(componentPricesSelector);
  const count = useSelector(componentPricesCountSelector);
  const selectedCurrency = useSelector(selectedCurrencySelector) || Currencies.DKK;
  const rate = selectedCurrency ? CurrenciesRatesMap[selectedCurrency] || 1 : 1;
  const allTableInvestmentComponentPricesFetched = useSelector(allTableComponentPricesFetchedSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchComponentPricesAction(params)).then(
        (action: Shared.ReduxAction<{ componentPricesHash: Setup.Root['componentPricesHash'] }>) =>
          Object.values(action.payload?.componentPricesHash || {})
      ),
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteComponentPriceAssetAction(id)),
        })
      );
    },
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ investmentComponentPriceAssetModalMeta: { id } }));
    },
    [dispatch]
  );

  const handleDuplicateBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ investmentComponentPriceAssetModalMeta: { id, isDuplicate: true } }));
    },
    [dispatch]
  );

  const paginationType = PaginationType.COMPONENT_PRICES;

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      containerClassName={classNames({ 'border-top-0 px-0': inContentContainer })}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 308px)"
      waitForDependencies={!allTableInvestmentComponentPricesFetched || !portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items?.map?.((item: Setup.ComponentPrice) => (
        <tr key={item.id}>
          <td>{item.asset_register_category}</td>
          <td>{numberFormat(item.size, { fallback: '-' })}</td>
          <td>{item.size_unit || '-'}</td>
          <td>{numberFormat(item.price / rate, { fallback: '-' })}</td>
          <td>{(item.price_unit || '').replace(Currencies.DKK, selectedCurrency)}</td>
          <td>{item.price_type || ''}</td>
          <td>{item.component_price_scenario || ''}</td>
          <td>{numberFormat(item.co2e_scope_1, { fallback: '-' })}</td>
          <td>{numberFormat(item.co2e_scope_2, { fallback: '-' })}</td>
          <td>{numberFormat(item.co2e_scope_3, { fallback: '-' })}</td>
          <td>{item.co2e_unit || '-'}</td>
          <td>
            {item.grid_zone
              .replace(/[{}]/g, '')
              .split(',')
              .filter(Boolean)
              .map(zone => getIntl(GriZoneNamesLocalesMap[zone] || zone))
              .join(', ') || '-'}
          </td>
          <td>{item.lifetime || ''}</td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              tooltipKey="Duplicate"
              icon={<IconDuplicate />}
              data-id={item.id}
              marginLeft
              onClick={handleDuplicateBtnClick}
              size="small"
            />
            <Button
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              marginLeft
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableComponentPrices;
