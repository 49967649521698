import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { tasksMaterialsModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { materialSelectorFactory } from 'modules/tasks/selectors';
import { Modal, Button, FormInput, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { IconPlus } from '@utiligize/shared/resources';

const ModalMaterials: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(tasksMaterialsModalIdSelector);
  const selector = useMemo(() => materialSelectorFactory(id), [id]);
  const material: Tasks.Material | undefined = useSelector(selector);
  const isEditMode: boolean = Boolean(id);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ tasksMaterialsModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, submitForm, setValues, resetForm } =
    useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!material) return;
    setValues({
      name: material.name,
      code: material.code,
      unit: material.unit,
    });
    setShow(true);
  }, [material, setValues]);

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create material" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit material' : 'Create material'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
              isNew
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Code"
              name="code"
              value={values.code}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.code && errors.code) ? (errors.code as string) : ''}
              isNew
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Unit"
              name="unit"
              value={values.unit || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.unit && errors.unit) ? (errors.unit as string) : ''}
              isNew
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalMaterials;
