import styled from 'styled-components';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getDurationChartData } from 'modules/networkLoading';
import { updatedChartYearSelector } from 'modules/newLoad/selectors';
import { portfolioIdSelector, scenarioIdSelector, selectedChartFlexSelector } from 'modules/layouts/selectors';
import { SelectDERsPreviewYear, Checkbox } from 'components/_common';
import Chart, { Colors, getBaseOptions } from './Chart';
import { setLayoutAction } from 'modules/layouts';

interface Props {
  uuid: string;
  height?: '100%';
  isTooltipOutside?: boolean;
}
interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  plotLines: Shared.XAxisPlotLinesOptions[];
  series: Shared.SeriesOptionsType[];
}

const AssetDurationChart: React.FC<Props> = ({ uuid, height, isTooltipOutside = false }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const year = useSelector(updatedChartYearSelector);
  const flex = useSelector(selectedChartFlexSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const baseOptions = getBaseOptions(getIntl, chartData);

  const handleSelectYear = useCallback(
    (selectedChartYear: number) => dispatch(setLayoutAction({ selectedChartYear })),
    [dispatch]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ selectedChartFlex: event.currentTarget.checked })),
    [dispatch]
  );

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId || !year) return;
    dispatch(getDurationChartData({ portfolioId, scenarioId, uuid, year, flex }))
      .then((action: Shared.ReduxAction<any>) => {
        const seriesHash = action.payload.data.reduce(
          (acc: Record<string, Shared.SeriesOptionsType>, item: { Year: string; hour: number; kVA: number }) => {
            if (!acc[item.Year]) {
              acc[item.Year] = {
                name: item.Year,
                type: 'line',
                data: [],
                color: Colors[Object.keys(acc).length],
                index: Number(item.Year),
                lineWidth: 0.5,
              };
            }
            (acc[item.Year] as any).data.push([item.hour, item.kVA]);
            return acc;
          },
          {}
        );

        const plotLines = (() => {
          const line = action.payload.horizontal_line;
          if (!line) return [];
          return [line, -line].map(value => ({
            color: '#c77cff',
            width: 2,
            zIndex: 5,
            value,
            label: {
              text: 'Rating',
              style: { color: '#c77cff', fontWeight: 'bold' },
            },
          }));
        })();

        return {
          series: Object.values<Shared.SeriesOptionsType>(seriesHash),
          title: action.payload.title,
          yAxisTitle: action.payload.ylabel,
          xAxisTitle: action.payload.xlabel,
          plotLines,
        };
      })
      .then(setChartData)
      .catch(() => setChartData({ series: [] } as any));
  }, [dispatch, portfolioId, scenarioId, uuid, year, flex]);

  const options = useMemo(() => {
    const isMultiLinesChart = chartData?.series.length! > 1;
    return {
      ...baseOptions,
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return this.points?.map(
            point =>
              `${isMultiLinesChart ? `${point.series.name}<br>` : ''}<b>kVA: </b>${point.y}<br><b>${getIntl(
                'Hours'
              )}: </b>${point.x}<br>`
          );
        },
        shared: true,
        outside: isTooltipOutside,
      },
      yAxis: {
        ...baseOptions.yAxis,
        plotLines: chartData?.plotLines?.map(p => ({
          ...p,
          label: { ...p.label, text: getIntl(p.label?.text || '') },
        })),
      },
      series: chartData?.series,
      legend: { enabled: isMultiLinesChart },
    };
  }, [baseOptions, chartData, getIntl, isTooltipOutside]) as Highcharts.Options;

  return (
    <>
      <StyledContainer data-marker="asset_timeseries__buttons_block">
        <SelectDERsPreviewYear
          labelKey=""
          value={year}
          onChange={handleSelectYear}
          variant="small"
          isSearchable={false}
        />
        <Checkbox
          labelKey="Flex"
          className="icheck-primary ml-2"
          name="flex"
          checked={flex}
          onChange={handleCheckboxClick}
        />
      </StyledContainer>
      <Chart dataMarker="asset_timeseries_chart" options={chartData ? options : null} height={height} />
    </>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 50%;
  transform: translate(50%);
  display: inline-flex;
  justify-content: space-between;
`;

export default AssetDurationChart;
