export enum StorageTypes {
  LOCAL_STORAGE = 'localStorage',
  SESSION_STORAGE = 'sessionStorage',
}

export enum StorageKeys {
  LANGUAGE = 'LANGUAGE',
  LAST_ROUTE = 'LAST_ROUTE',
  USER_CLAIMS = 'USER_CLAIMS',
  SELECTED_TENANT = 'SELECTED_TENANT',
  PAGINATION = 'PAGINATION',
  PAGINATION_CACHE_VERSION = 'PAGINATION_CACHE_VERSION',
  PORTFOLIO_ID = 'PORTFOLIO_ID',
  SCENARIO_ID_HASH = 'SCENARIO_ID_HASH',
  INVESTMENT_SCENARIO_ID = 'INVESTMENT_SCENARIO_ID',

  MAP_CACHE = 'MAP_CACHE',
  SELECTED_MAP_THEME = 'SELECTED_MAP_THEME',
  MUTE_ERROR_TOAST_ACTION = 'MUTE_ERROR_TOAST_ACTION',
  SELECTED_TIME_ZONE = 'SELECTED_TIME_ZONE',
  MAP_PANEL_ENABLED = 'MAP_PANEL_ENABLED',
  MAP_PANEL_TOP_TAB_ACTIVE_INDEX = 'MAP_PANEL_TOP_TAB_ACTIVE_INDEX',
  MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX = 'MAP_PANEL_BOTTOM_TAB_ACTIVE_INDEX',
  SELECTED_CHART_YEAR = 'SELECTED_CHART_YEAR',
  SELECTED_CHART_FLEX = 'SELECTED_CHART_FLEX',
  CO2E_CUMULATIVE_OVERVIEW = 'CO2E_CUMULATIVE_OVERVIEW',
  CO2E_SCENARIO_YEAR = 'CO2E_SCENARIO_YEAR',
  MAINTENANCE_AVERAGE_TIME_SPENT_BY_ASSET_CATEGORY = 'MAINTENANCE_AVERAGE_TIME_SPENT_BY_ASSET_CATEGORY',
  MAINTENANCE_TIME_BY_ACTION_TYPE_ASSET_CATEGORIES = 'MAINTENANCE_TIME_BY_ACTION_TYPE_ASSET_CATEGORIES',
  MAP_DRAWN_FEATURES = 'MAP_DRAWN_FEATURES',
  RELEASE_NOTIFICATION_MODAL_VERSION = 'RELEASE_NOTIFICATION_MODAL_VERSION',
  ASSET_SPECIFICATION_MODAL_COMPONENT_PRICE_SCENARIO_ID = 'ASSET_SPECIFICATION_MODAL_COMPONENT_PRICE_SCENARIO_ID',

  FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM = 'FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM',
  FEATURE_TOGGLE_WORK_PRICES = 'FEATURE_TOGGLE_WORK_PRICES',
  FEATURE_TOGGLE_VOLTAGE_DROP = 'FEATURE_TOGGLE_VOLTAGE_DROP',
  FEATURE_TOGGLE_FEEDERS_ANIMATION = 'FEATURE_TOGGLE_FEEDERS_ANIMATION',
  FEATURE_TOGGLE_DRAW_ASSETS = 'FEATURE_TOGGLE_DRAW_ASSETS',
  FEATURE_MAP_CONNECTIONS = 'FEATURE_MAP_CONNECTIONS',
  FEATURE_GIS_LAYERS = 'FEATURE_GIS_LAYERS',
  FEATURE_SHORT_CIRCUIT = 'FEATURE_SHORT_CIRCUIT',
  FEATURE_TOGGLE_SINGLE_LINE_CONTROLS = 'FEATURE_TOGGLE_SINGLE_LINE_CONTROLS',
  FEATURE_HIGH_VOLTAGE_CABLES_ANIMATION = 'FEATURE_HIGH_VOLTAGE_CABLES_ANIMATION',
  FEATURE_CURRENCIES = 'FEATURE_CURRENCIES',
  FEATURE_NEW_DENMARK_SATELLITE_TILES = 'FEATURE_NEW_DENMARK_SATELLITE_TILES',
  FEATURE_MAP_DER_AI_THEMES = 'FEATURE_MAP_DER_AI_THEMES',
}
