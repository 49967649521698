import React, { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useLocale } from 'hooks';
import { isSingleLineDiagramEnabledSelector } from 'modules/app/selectors';
import { issuesCountSelector, issuesSelector } from 'modules/dataQuality/selectors';
import { fetchIssuesAction } from 'modules/dataQuality';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { MapThemeGroups, MapThemes, PaginationType, Routes, StorageKeys } from 'constants/index';
import { getStorageItem, setStorageItem } from 'utils';
import DataQualityIgnoredOption from './DataQualityIgnoredOption';
import { IconMap, IconDataFlow4 } from '@utiligize/shared/resources';

const TableIssues: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ISSUES;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const items = useSelector(issuesSelector);
  const count = useSelector(issuesCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const isSingleLineDiagramEnabled = useSelector(isSingleLineDiagramEnabledSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      return dispatch(fetchIssuesAction(params)).then(
        (action: Shared.ReduxAction<{ issues: DataQuality.Root['issues'] }>) => action.payload?.issues || []
      );
    },
    [dispatch]
  );

  const handleMapLinkClick = useCallback(() => {
    setStorageItem({
      [StorageKeys.MAP_CACHE]: {
        ...(getStorageItem<Map.MapState>(StorageKeys.MAP_CACHE) || {}),
        theme: MapThemes.DATA_QUALITY,
        themeGroup: MapThemeGroups.FORECAST_AND_INVESTMENT,
      },
    });
  }, []);

  const handleSingleLineButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(
        setLayoutAction({
          singleDiagramEnabled: true,
          isExtendedGridEnabled: false,
          isMapConnectionsEnabled: false,
          mapPanelEnabled: true,
        })
      );
      const singleLineDiagramFilterPrimarySubstationsId: number | null = Number(
        event.currentTarget.getAttribute('data-primary_substation_id')
      );
      const singleLineDiagramFilterVoltagesIds: number[] | null =
        event.currentTarget.getAttribute('data-voltage_id')?.split(',').map(Number) || null;
      if (singleLineDiagramFilterPrimarySubstationsId && singleLineDiagramFilterVoltagesIds) {
        dispatch(setLayoutAction({ singleLineDiagramFilterPrimarySubstationsId, singleLineDiagramFilterVoltagesIds }));
      }
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 255px)"
      waitForDependencies={!portfolioId || !scenarioId || !filters?.voltageLevels}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
    >
      {items?.map((item: DataQuality.Issue) => (
        <tr key={item.issue_id}>
          <td>{item.id}</td>
          <td className="text-nowrap">{item.opened_time ? dateFormat(item.opened_time, 'L LT') : '-'}</td>
          <td className={classNames({ 'bg-danger': item.code?.toString()[0] === '1' })}>{item.code}</td>
          <td className="text-center">
            <DataQualityIgnoredOption issueId={item.issue_id} checked={item.is_ignored} />
          </td>
          <td>{item.ignored_explanation || '-'}</td>
          <td className="text-break">{item.warning}</td>
          <td className="text-right">
            <Button
              tooltipKey={item.positiononthemap ? 'Show on map' : 'Assets are not shown on the map'}
              icon={<IconMap />}
              linkProps={{
                to: {
                  pathname: generatePath(Routes.Map),
                  state: {
                    issue_id: item.issue_id,
                    code: item.code,
                    positiononthemap: item.positiononthemap,
                    warning: item.warning,
                  },
                },
              }}
              size="small"
              disabled={!item.positiononthemap}
              onClick={handleMapLinkClick}
            />
            {isSingleLineDiagramEnabled && item.array_asset_uuids && item.code !== 1000 && (
              <Button
                tooltipKey="Show on single line diagram"
                icon={<IconDataFlow4 />}
                linkProps={{
                  to: {
                    pathname: generatePath(Routes.Map),
                    state: { array_asset_uuids: item.array_asset_uuids },
                  },
                }}
                data-primary_substation_id={item.primary_substation_id}
                data-voltage_id={item.voltage_id}
                onClick={handleSingleLineButtonClick}
                size="small"
                marginLeft
              />
            )}
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableIssues;
