import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { checklistsCountSelector, checklistsSelector } from 'modules/history/selectors';
import { fetchHistoryChecklistsAction, deleteHistoryChecklistAction } from 'modules/history';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const TableChecklists: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(checklistsCountSelector);
  const items = useSelector(checklistsSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchHistoryChecklistsAction(params)).then(
        (action: Shared.ReduxAction<{ checklistsHash: History.Root['checklistsHash'] }>) =>
          Object.values(action.payload?.checklistsHash || {})
      ),
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (id) dispatch(setLayoutAction({ historyChecklistsModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteHistoryChecklistAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.HISTORY_CHECKLISTS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
    >
      {items?.map((item: History.Checklist) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>
            {getIntl(item.assetCategory.name)} ({item.assetCategory.code})
          </td>
          <td>
            {item.questions?.map((question: string) => (
              <React.Fragment key={question}>
                - {question}
                <br />
              </React.Fragment>
            ))}
          </td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableChecklists;
