import React, { useState, useMemo, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLogMaintenanceFormsAction } from 'modules/forms';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  assetCategoryCode: Type.AssetCategories | null;
  assetCodes?: string[];
}

type FormItem = { id: number; name: string; assetCodes: string[] };

const SelectLogMaintenanceForms: React.FC<Props> = memo(
  ({
    labelKey = 'Form',
    errorKey = '',
    isDisabled = false,
    value,
    assetCategoryCode,
    assetCodes,
    onChange,
    ...props
  }) => {
    const dispatch: Shared.CustomDispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [forms, setForms] = useState<FormItem[]>([]);

    useEffect(() => {
      if (!assetCategoryCode) return setLoading(false);
      dispatch(fetchLogMaintenanceFormsAction(assetCategoryCode) as any)
        .then((action: Shared.ReduxAction<FormItem[]>) => {
          setForms(
            action.payload.filter(
              form =>
                !form.assetCodes.length ||
                !assetCodes ||
                assetCodes.every((code: string) => form.assetCodes.includes(code))
            )
          );
          setLoading(false);
        })
        .catch(console.error);
    }, [assetCategoryCode, assetCodes, dispatch]);

    const formsSelectOptions = useMemo(() => forms.map(({ id, name }) => ({ value: id, label: name })), [forms]);

    return (
      <FormReactSelect
        labelKey={labelKey}
        errorKey={errorKey}
        isLoading={loading}
        isDisabled={loading || isDisabled}
        value={value ? { value, label: forms.find(f => f.id === value)?.name || '' } : null}
        options={formsSelectOptions}
        onChange={onChange}
        {...props}
      />
    );
  }
);

export default SelectLogMaintenanceForms;
