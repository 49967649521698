import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { isLiveDataConnectionSelector } from 'modules/app/selectors';
import { ViewHistoryDetails, ViewAnswerDetails, ViewChecklists, TableAnswers, TableHistory } from 'components/History';
import PagePermissionsProvider from './PagePermissionsProvider';
import PageAppExceptions from './PageAppExceptions';
import { Routes, PermissionsTypes } from 'constants/index';

const PageHistory: React.FC = () => {
  const isLiveDataConnection = useSelector(isLiveDataConnectionSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isHistoryEnabled}>
      <Switch>
        <Route
          exact
          path={Routes.HistoryAnswers}
          render={
            ((props: RouteComponentProps<{ id: string }>) => {
              return props.match.params.id ? <ViewAnswerDetails {...props} /> : <TableAnswers />;
            }) as any
          }
        />
        {!isLiveDataConnection && (
          <Route exact path={Routes.HistoryChecklists}>
            <ViewChecklists />
          </Route>
        )}
        <Route
          exact
          path={Routes.History}
          render={
            ((props: RouteComponentProps<{ id: string }>) => {
              return props.match.params.id ? (
                <ViewHistoryDetails {...props} />
              ) : (
                <TableHistory isLiveDataConnection={isLiveDataConnection} />
              );
            }) as any
          }
        />
        <Route path="*" component={PageAppExceptions} />
      </Switch>
    </PagePermissionsProvider>
  );
};

export default PageHistory;
