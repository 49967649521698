import React from 'react';
import { useSelector } from 'react-redux';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import AuxiliaryLayersOther from './AuxiliaryLayersOther';
import AuxiliaryLayersCNAIM from './AuxiliaryLayersCNAIM';
import GroupContainer from './GroupContainer';
import GroupTitle from './GroupTitle';
import GroupSection from './GroupSection';

const GroupAuxiliaryLayers: React.FC = () => {
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  if (singleDiagramEnabled) return null;
  return (
    <GroupContainer>
      <GroupTitle titleKey="Auxiliary layers" />
      <GroupSection>
        <AuxiliaryLayersOther />
      </GroupSection>
      <GroupSection>
        <AuxiliaryLayersCNAIM />
      </GroupSection>
    </GroupContainer>
  );
};

export default GroupAuxiliaryLayers;
