import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createHistoryChecklistAction, editHistoryChecklistAction } from 'modules/history';
import { _omit } from '@utiligize/shared/utils';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Type.HistoryChecklistActionProps>({
    initialValues: {
      name: '',
      assetCategoryCode: null,
      questionInput: '',
      questions: [],
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      assetCategoryCode: Yup.string().nullable().required("Field can't be empty!"),
      questions: Yup.array().min(1, 'Please add at least one question'),
    }),

    onSubmit: values => {
      const data = _omit(values, 'questionInput');
      return (() => {
        return id ? dispatch(editHistoryChecklistAction(id, data)) : dispatch(createHistoryChecklistAction(data));
      })()
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
