import styled from 'styled-components';
import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  portfolioOptionsSelector,
  scenarioOptionsHashSelector,
  simulationOptionsHashSelector,
} from 'modules/options/selectors';
import { DataTableTemplate, ContentContainer, ContentCard, Button, Checkbox } from 'components/_common';
import SelectChartType, { ChartTypeKeys, ChartTypes, ChartTypesOption } from './SelectChartType';
import SelectAnalysisToolPortfolio from './SelectAnalysisToolPortfolio';
import SelectAnalysisToolScenario from './SelectAnalysisToolScenario';
import SelectAnalysisToolSimulation from './SelectAnalysisToolSimulation';
import ChartAnalysis from './ChartAnalysis';
import ChartInvestmentSummary from './ChartInvestmentSummary';
import ChartCO2Emissions from './ChartCO2Emissions';
import ChartDERPhaseInProfile from './ChartDERPhaseInProfile';
import { IconDelete, IconPlus } from '@utiligize/shared/resources';
import SelectVoltage from 'components/NetworkLoading/SelectVoltage';
import { TransformerVoltages, CableVoltages } from 'constants/index';

export type IDsState = {
  id: string;
  portfolioId: number;
  scenarioId: number;
  simulationId: number | null;
  versionId: number | null;
  flex: boolean;
};

const ViewAnalysisTool: React.FC = () => {
  const maxIds = 4;
  const [typeOption, setTypeOption] = useState(ChartTypes.TR_LOADING);
  const [loading, setLoading] = useState<boolean>(false);
  const isChartAnalysis = [
    ChartTypeKeys.TR_LOADING,
    ChartTypeKeys.TR_OVERLOADING,
    ChartTypeKeys.CABLES_LOADING,
    ChartTypeKeys.CABLES_OVERLOADING,
  ].includes(typeOption.key);
  const isInvestments = typeOption.key === ChartTypeKeys.INVESTMENTS;
  const isEmissions = typeOption.key === ChartTypeKeys.DETAILED_EMISSIONS;
  const isDERPhaseInProfile = typeOption.key === ChartTypeKeys.DER_PHASE_IN_PROFILE;
  const [voltage, setVoltage] = useState<Layouts.TransformerVoltages | Layouts.CableVoltages>(
    TransformerVoltages['10/0.4kV transformer']
  );

  const getVoltages = useCallback(
    (key: ChartTypeKeys) =>
      [ChartTypeKeys.TR_LOADING, ChartTypeKeys.TR_OVERLOADING].includes(key) ? TransformerVoltages : CableVoltages,
    []
  );

  const voltages = getVoltages(typeOption.key);

  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);
  const simulationOptionsHash = useSelector(simulationOptionsHashSelector);

  const getInitPortfolioScenarioFlexState = useCallback(() => {
    return portfolioOptions?.reduce((acc: any, { value }) => {
      const scenarioOptions = scenarioOptionsHash?.[value];
      if (!scenarioOptions || acc.length >= 2) return acc;
      scenarioOptions.forEach(option => {
        if (option.value && acc.length < 2) {
          const { value: simulationId, versionId } = simulationOptionsHash[`${value}_${option.value}`]?.[0] || {};
          acc.push({
            id: crypto.randomUUID(),
            portfolioId: value,
            scenarioId: option.value,
            flex: false,
            simulationId,
            versionId,
          });
        }
      });
      return acc;
    }, []);
  }, [portfolioOptions, scenarioOptionsHash, simulationOptionsHash]);

  const [ids, setIds] = useState<IDsState[]>(getInitPortfolioScenarioFlexState());

  const handleAddSetClick = useCallback(() => {
    const portfolioId =
      portfolioOptions?.filter(option => !ids.some(id => id.portfolioId === option.value))?.[0]?.value ||
      ids[0].portfolioId;

    const scenarioId =
      scenarioOptionsHash[portfolioId]?.filter(option => !ids.some(id => id.scenarioId === option.value))?.[0]?.value ||
      ids[0].scenarioId;

    const { value: simulationId, versionId } = simulationOptionsHash[`${portfolioId}_${scenarioId}`]?.[0] || {};
    setIds(prev => {
      const prevArr = prev.length === maxIds ? prev.filter(i => !i.flex) : prev;
      return [...prevArr, { id: crypto.randomUUID(), portfolioId, scenarioId, flex: false, simulationId, versionId }];
    });
  }, [portfolioOptions, scenarioOptionsHash, simulationOptionsHash, ids]);

  const handleDeleteBtnClick = useCallback((event: React.SyntheticEvent) => {
    const id = event.currentTarget.getAttribute('data-id');
    setIds(prev => prev.filter(item => item.id !== id));
  }, []);

  const handleCheckboxClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.currentTarget.getAttribute('data-id');
    const flex = event.currentTarget.checked;
    setIds(prev => prev.map(item => (item.id === id ? { ...item, flex } : item)));
  }, []);

  const handleTypeOptionChange = useCallback(
    (option: ChartTypesOption) => {
      const nextVoltagesOptions = getVoltages(option.key);
      const isCurrentVoltageExist = Object.keys(nextVoltagesOptions).includes(voltage);
      if (!isCurrentVoltageExist) {
        setVoltage(Object.keys(nextVoltagesOptions)[0] as Layouts.TransformerVoltages | Layouts.CableVoltages);
      }
      setTypeOption(option);
    },
    [getVoltages, voltage]
  );

  const validIds = useMemo(
    () =>
      ids.filter(
        item => item.portfolioId && item.scenarioId && (isDERPhaseInProfile || (item.simulationId && item.versionId))
      ),
    [isDERPhaseInProfile, ids]
  );

  return (
    <DataTableTemplate paddingBottom>
      <>
        <Row className="mb-3" style={{ rowGap: '1rem' }}>
          {ids.map(({ id, portfolioId, scenarioId, flex, simulationId }) => (
            <Col key={id} xs={3}>
              <StyledCard>
                <div className="mb-2">
                  <SelectAnalysisToolPortfolio
                    value={portfolioId}
                    options={portfolioOptions || []}
                    id={id}
                    setIds={setIds}
                    isDisabled={loading}
                  />
                </div>
                <SelectAnalysisToolScenario
                  value={scenarioId}
                  options={scenarioOptionsHash?.[portfolioId] || []}
                  id={id}
                  setIds={setIds}
                  isDisabled={loading}
                />
                {(isChartAnalysis || isEmissions || isInvestments) && (
                  <div className="mt-2">
                    <SelectAnalysisToolSimulation
                      value={simulationId}
                      options={simulationOptionsHash?.[`${portfolioId}_${scenarioId}`]}
                      id={id}
                      setIds={setIds}
                      isDisabled={loading}
                    />
                  </div>
                )}
                {isInvestments && (
                  <StyledCheckbox
                    labelKey="Flexible"
                    className="icheck-default"
                    checked={flex}
                    onChange={handleCheckboxClick}
                    data-id={id}
                  />
                )}
                {ids.length > 2 && (
                  <StyledDeleteCardButton
                    variant="primary-link"
                    icon={<IconDelete />}
                    data-id={id}
                    tooltipKey="Delete"
                    onClick={handleDeleteBtnClick}
                    size="small"
                    aria-label="Delete"
                    disabled={loading}
                  />
                )}
              </StyledCard>
            </Col>
          ))}
          {ids?.length < maxIds && (
            <StyledButtonContainer xs="auto">
              <Button
                tooltipKey="Add set"
                icon={<IconPlus />}
                variant="primary"
                onClick={handleAddSetClick}
                disabled={loading}
              />
            </StyledButtonContainer>
          )}
        </Row>
        <Row>
          <Col xs={3}>
            <SelectChartType value={typeOption.value} onChange={handleTypeOptionChange} isDisabled={loading} />
          </Col>
          {isChartAnalysis && (
            <Col xs={3}>
              <SelectVoltage
                labelKey=""
                mutedTextLabelKey="Voltage"
                value={voltage}
                voltages={voltages}
                onChange={setVoltage}
                variant="small"
                isDisabled={loading}
              />
            </Col>
          )}
        </Row>
      </>
      <ContentContainer>
        <ContentCard>
          {isChartAnalysis && (
            <ChartAnalysis
              key={typeOption.key + voltage}
              ids={validIds}
              voltage={voltage}
              typeOption={typeOption}
              setLoading={setLoading}
            />
          )}
          <ChartInvestmentSummary ids={validIds} setLoading={setLoading} isInvestments={isInvestments} />
          <ChartCO2Emissions ids={validIds} setLoading={setLoading} isEmissions={isEmissions} />
          <ChartDERPhaseInProfile ids={ids} setLoading={setLoading} isDERPhaseInProfile={isDERPhaseInProfile} />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

const StyledCard = styled.div`
  position: relative;
  padding: 10px;
  border: 1px solid #e1e6ec;
  border-radius: 8px;
`;

const StyledDeleteCardButton = styled(Button)`
  position: absolute;
  right: 0px;
  bottom: 0.5rem;
`;

const StyledButtonContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0 !important;

  > label {
    color: #6c757d;
    font-size: 12px !important;
  }
`;

export default ViewAnalysisTool;
