import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createTaskMaterialAction, editTaskMaterialAction } from 'modules/tasks';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Tasks.Material>({
    initialValues: {
      name: '',
      code: '',
      unit: '',
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      code: Yup.string().required("Field can't be empty!"),
      unit: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const data = { ...values, questionInput: undefined };
      if (id) return dispatch(editTaskMaterialAction(id, data)).then(toggleModal);
      return dispatch(createTaskMaterialAction(data)).then(toggleModal);
    },
  });

  return formik;
};

export default useConfiguredFormik;
