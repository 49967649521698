import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  isCO2eCumulativeOverviewSelector,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { getCO2EmissionChartData } from 'modules/co2e';
import Chart, { Colors } from 'components/_charts/Chart';
import { _snakecase } from '@utiligize/shared/utils';

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
}

const transformChartData = (
  action: Shared.ReduxAction<Type.CO2EmissionChartDataItem[]>,
  getIntl: (localeKey: string) => string,
  {
    type,
    ResponseUsedKeysLocalesMap,
  }: { type: 'line' | 'column'; ResponseUsedKeysLocalesMap: Props['ResponseUsedKeysLocalesMap'] }
): ChartDataProps => {
  if (!Object.keys(action.payload || {}).length) return {} as ChartDataProps;

  const keys = Object.keys(action.payload[0] || {}).filter(key => !['year'].includes(key));

  const seriesHash = action.payload.reduce(
    (acc: Record<string, Shared.SeriesOptionsType>, item: Type.CO2EmissionChartDataItem) => {
      keys
        .filter(key => ResponseUsedKeysLocalesMap[key as keyof Type.CO2EmissionChartDataItem])
        .forEach((key, index) => {
          const elem = [item.year, item[key as keyof Type.CO2EmissionChartDataItem]];
          if (!acc[key]) {
            acc[key] = {
              name: getIntl(ResponseUsedKeysLocalesMap[key as keyof Type.CO2EmissionChartDataItem] || ''),
              type,
              data: [elem],
              color: Colors[index],
              index,
              lineWidth: 2,
            };
          } else {
            (acc[key] as any).data.push(elem);
          }
        });
      return acc;
    },
    {}
  );

  return {
    series: Object.values<Shared.SeriesOptionsType>(seriesHash || {}),
  };
};

interface Props {
  enableColumnChartWhenConditionsMet?: boolean;
  titleKey: string;
  ResponseUsedKeysLocalesMap: { [key in keyof Type.CO2EmissionChartDataItem]?: string };
}

const ChartCO2Emission: React.FC<Props> = ({
  enableColumnChartWhenConditionsMet,
  titleKey,
  ResponseUsedKeysLocalesMap,
}) => {
  const { getIntl } = useLocale();
  const isCO2eCumulativeOverview = useSelector(isCO2eCumulativeOverviewSelector);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const type = enableColumnChartWhenConditionsMet && !isCO2eCumulativeOverview ? 'column' : 'line';

  useEffect(() => {
    if (!portfolioId || !scenarioId || !simulationId || !versionId) return setChartData(null);
    dispatch(getCO2EmissionChartData({ portfolioId, scenarioId, simulationId, versionId, isCO2eCumulativeOverview }))
      .then(action => transformChartData(action, getIntl, { ResponseUsedKeysLocalesMap, type }))
      .then(setChartData);
  }, [
    dispatch,
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    isCO2eCumulativeOverview,
    getIntl,
    ResponseUsedKeysLocalesMap,
    type,
  ]);

  const options = useMemo(
    () => ({
      chart: {
        zoomType: 'x',
      },
      title: {
        text: getIntl(titleKey),
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
          marker: {
            enabled: false,
          },
        },
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
      },
      yAxis: {
        title: {
          text: getIntl('CO₂e (t/year)'),
        },
      },
      series: chartData?.series || [],
    }),
    [chartData, getIntl, titleKey]
  ) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker={`chart_co2e_${_snakecase(titleKey)}`} />;
};

export default ChartCO2Emission;
