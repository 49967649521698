import React, { useEffect, useCallback } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { manualsItemByIdSelectorFactory } from 'modules/manuals/selectors';
import { Button, SelectAssetCategories, FormInput, SelectModels, SelectAssetCodes } from 'components/_common';
import { FormikProps } from './useConfiguredFormik';
import { convertPdfToPng } from 'utils';

interface Props {
  formik: FormikProps;
  id: number | null;
}

const ManualsModalForm: React.FC<Props> = ({ formik, id }) => {
  const { getIntl } = useLocale();
  const isEditMode: boolean = Boolean(id);
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setValues } = formik;
  const item: Manuals.Item | null = useSelector(manualsItemByIdSelectorFactory(id));

  // set form initial values asynchronously for isEditMode
  useEffect(() => {
    if (!item) return;
    setValues({
      file: item.name,
      filePreview: '',
      name: item.name,
      categoryId: item.categoryId || null,
      assetCode: item.assetCode || '',
      // main manuals API table call doesn't return assignedModels so empty array applied
      modelIds: item.assignedModels.map(i => i.modelId) || [],
    });
  }, [item, setValues]);

  const handleFileUpload = useCallback(
    (e: React.SyntheticEvent) => {
      const file: Blob | undefined = (e.currentTarget as HTMLInputElement).files?.[0];
      if (!file) return;
      convertPdfToPng(file, (images: Blob[]) => {
        setFieldValue('file', file);
        setFieldValue('filePreview', images[0]);
      });
    },
    [setFieldValue]
  );

  return (
    <Form>
      <Form.Row className="justify-content-around align-items-end">
        <Form.Group as={Col} sm={5}>
          {isEditMode ? (
            <a href={item?.link} target="_blank" rel="noopener noreferrer">
              <Button size="large" labelKey="Preview Manual" />
            </a>
          ) : (
            <>
              <Form.File
                name="file"
                label={getIntl('File')}
                accept="application/pdf"
                onChange={handleFileUpload}
                isInvalid={Boolean(touched.file && errors.file)}
                feedback={touched.file ? getIntl(errors.file as string) : ''}
              />
              {!Boolean(touched.file && errors.file) && (
                <Form.Text muted>{getIntl('Please upload file, only PDF accept')}</Form.Text>
              )}
            </>
          )}
        </Form.Group>
        <Form.Group as={Col} sm={5}>
          <FormInput
            autoFocus
            labelKey="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row className="justify-content-around">
        <Form.Group as={Col} sm={5}>
          <SelectAssetCategories
            name="categoryId"
            label={
              <>
                {getIntl('Asset category')} <small>({getIntl('Optional')})</small>
              </>
            }
            value={values.categoryId}
            isIdValue
            onChange={(value: any) => setFieldValue('categoryId', value?.value || null)}
            excludeSyntheticOptions
          />
        </Form.Group>
        <Form.Group as={Col} sm={5}>
          <SelectAssetCodes
            label={
              <>
                {getIntl('Specific asset code')} <small>({getIntl('Optional')})</small>
              </>
            }
            values={[values.assetCode].filter(Boolean) as string[]}
            onChange={(value: Type.SelectOption | null) => setFieldValue('assetCode', value?.value || null)}
          />
        </Form.Group>
      </Form.Row>
      <SelectModels modelIds={values.modelIds} setValue={modelIds => setFieldValue('modelIds', modelIds)} />
    </Form>
  );
};

export default ManualsModalForm;
