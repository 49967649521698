import { createSelector } from 'reselect';
import { componentPriceTypeOptionsSelector } from 'modules/options/selectors';
import { MAX_FORECASTED_SCENARIOS } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.setup;

export const forecastedScenariosCountSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['forecastedScenariosCount'] => setup.forecastedScenariosCount
);

export const isMaxForecastedScenariosReachedSelector = createSelector(
  forecastedScenariosCountSelector,
  count => count >= MAX_FORECASTED_SCENARIOS
);

const forecastedScenariosHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['forecastedScenariosHash'] => setup.forecastedScenariosHash
);

export const forecastedScenariosSelector = createSelector(
  forecastedScenariosHashSelector,
  (forecastedScenariosHash: Setup.Root['forecastedScenariosHash']): Setup.ForecastedScenario[] =>
    Object.values(forecastedScenariosHash)
);

export const forecastedScenarioSelectorFactory = (id: number | null) =>
  createSelector(
    forecastedScenariosHashSelector,
    (forecastedScenariosHash: Setup.Root['forecastedScenariosHash']): Setup.ForecastedScenario =>
      forecastedScenariosHash[`_${id}_`]
  );

export const investmentScenariosHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['investmentScenariosHash'] => setup.investmentScenariosHash
);

export const investmentScenariosSelector = createSelector(
  investmentScenariosHashSelector,
  (investmentScenariosHash: Setup.Root['investmentScenariosHash']): Setup.InvestmentScenario[] =>
    Object.values(investmentScenariosHash)
);

export const investmentScenariosMetaCurrencySelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['investmentScenariosMetaCurrency'] => setup.investmentScenariosMetaCurrency
);

export const investmentScenarioSelectorFactory = (id: number | null) =>
  createSelector(
    investmentScenariosHashSelector,
    (investmentScenariosHash: Setup.Root['investmentScenariosHash']): Setup.InvestmentScenario =>
      investmentScenariosHash[`_${id}_`]
  );

export const investmentScenarioPriceSelector = createSelector(
  investmentScenariosSelector,
  (investmentScenarios: Setup.InvestmentScenario[]): Setup.InvestmentScenario['price_per_mwh'] | null =>
    investmentScenarios?.[0]?.price_per_mwh || null
);

export const CO2eSelector = createSelector(rootSelector, (setup: Setup.Root): Setup.CO2e[] => setup.CO2e);

export const rawDataCountSelector = createSelector(rootSelector, (setup: Setup.Root): number => setup.rawDataCount);

const rawDataHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Type.Hash<Setup.RawData> => setup.rawDataHash
);

export const rawDataSelector = createSelector(
  rawDataHashSelector,
  (rawDataHash: Type.Hash<Setup.RawData>): Setup.RawData[] => Object.values(rawDataHash)
);

export const rawDataMetaSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.RawDataMeta | null => setup.rawDataMeta
);

export const componentPriceScenariosCountSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['componentPriceScenariosCount'] => setup.componentPriceScenariosCount
);

export const componentPriceScenariosHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['componentPriceScenariosHash'] => setup.componentPriceScenariosHash
);

export const componentPriceScenariosSelector = createSelector(
  componentPriceScenariosHashSelector,
  (componentPriceScenariosHash: Setup.Root['componentPriceScenariosHash']): Setup.ComponentPriceScenario[] =>
    Object.values(componentPriceScenariosHash)
);

export const componentPriceScenarioSelectorFactory = (id: number | null) =>
  createSelector(
    componentPriceScenariosHashSelector,
    (componentPriceScenariosHash: Setup.Root['componentPriceScenariosHash']): Setup.ComponentPriceScenario =>
      componentPriceScenariosHash[`_${id}_`]
  );

const componentPricesHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['componentPricesHash'] => setup.componentPricesHash
);

export const componentPricesSelector = createSelector(
  componentPricesHashSelector,
  (componentPricesHash: Setup.Root['componentPricesHash']): Setup.ComponentPrice[] => Object.values(componentPricesHash)
);

export const componentPriceSelectorFactory = (id: number | null) =>
  createSelector(
    componentPricesHashSelector,
    (componentPricesHash: Setup.Root['componentPricesHash']): Setup.ComponentPrice => componentPricesHash[`_${id}_`]
  );

export const componentPricesCountSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): number => setup.componentPricesCount
);

export const allTableComponentPricesFetchedSelector = createSelector(
  [componentPriceTypeOptionsSelector],
  (componentPriceTypeOptions: Type.SelectOption[] | null): boolean => Boolean(componentPriceTypeOptions?.length)
);

export const investmentLimitationsByVoltageNameHashSelector = createSelector(
  [rootSelector],
  (setup: Setup.Root): Setup.Root['investmentLimitationsByVoltageNameHash'] =>
    setup.investmentLimitationsByVoltageNameHash
);

export const investmentLimitationVoltageUnitSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['investmentLimitationVoltageUnit'] => setup.investmentLimitationVoltageUnit
);

const investmentBudgetHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['investmentBudgetHash'] => setup.investmentBudgetHash
);

export const investmentBudgetSelector = createSelector(
  investmentBudgetHashSelector,
  (investmentBudgetHash: Setup.Root['investmentBudgetHash']): Setup.InvestmentBudget[] =>
    Object.values(investmentBudgetHash)
);

export const investmentBudgetCountSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['investmentBudgetCount'] => setup.investmentBudgetCount
);

export const investmentBudgetMetaSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['investmentBudgetMeta'] => setup.investmentBudgetMeta
);

export const scenarioCalculationHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['scenarioCalculationHash'] => setup.scenarioCalculationHash
);

export const scenarioCalculationSelector = createSelector(
  scenarioCalculationHashSelector,
  (scenarioCalculationHash: Setup.Root['scenarioCalculationHash']): Setup.ScenarioCalculation[] =>
    Object.values(scenarioCalculationHash)
);

// ------------------------------------
// Work Price Selectors
// ------------------------------------
export const workPricesCountSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['workPricesCount'] => setup.workPricesCount
);

const workPricesHashSelector = createSelector(
  rootSelector,
  (setup: Setup.Root): Setup.Root['workPricesHash'] => setup.workPricesHash
);

export const workPricesSelector = createSelector(
  workPricesHashSelector,
  (workPricesHash: Setup.Root['workPricesHash']): Setup.WorkPrice[] => Object.values(workPricesHash)
);

export const workPriceSelectorFactory = (id: Setup.WorkPrice['work_type_price_id'] | null) =>
  createSelector(
    workPricesHashSelector,
    (workPricesHash: Setup.Root['workPricesHash']): Setup.WorkPrice => workPricesHash[`_${id}_`]
  );
