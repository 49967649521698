import styled from 'styled-components';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { fetchHistoryDetailsAction } from 'modules/history';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import {
  DataTableTemplate,
  BackToTableButton,
  ContentContainer,
  ContentCard,
  Spinner,
  Alert,
} from 'components/_common';
import HistoryDetailsChecklist from './HistoryDetailsChecklist';
import { Routes } from 'constants/index';

const ViewHistoryDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match, location }) => {
  const id: number = Number(match.params.id);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, dateFormat } = useLocale();
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const [data, setData] = useState<History.Item | null>(null);

  useEffect(() => {
    if (!portfolioId || !versionId) return;
    setData(null);
    dispatch(fetchHistoryDetailsAction({ id, portfolioId, versionId }))
      .then((action: Shared.ReduxAction<History.Item>) => setData(action.payload))
      .catch(() => dispatch(push(generatePath(Routes.History))));
  }, [id, portfolioId, versionId, dispatch]);

  const diff = useMemo(() => {
    const obj1 = data?.originalAssetMetadata || ({} as History.AssetMetaData);
    const obj2 = data?.assetMetadata || ({} as History.AssetMetaData);
    const diff = {} as Record<keyof History.AssetMetaData, { from: any; to: any }>;

    // Check keys in obj1
    for (let key in obj1) {
      if (!(key in obj2) || obj1[key as keyof History.AssetMetaData] !== obj2[key as keyof History.AssetMetaData]) {
        diff[key as keyof History.AssetMetaData] = {
          from: obj1[key as keyof History.AssetMetaData],
          to: obj2[key as keyof History.AssetMetaData] || undefined,
        };
      }
    }

    // Check keys in obj2 that are not in obj1
    for (let key in obj2) {
      if (!(key in obj1)) {
        diff[key as keyof History.AssetMetaData] = { from: undefined, to: obj2[key as keyof History.AssetMetaData] };
      }
    }

    return diff;
  }, [data]);

  return (
    <DataTableTemplate paddingBottom>
      <Link to={generatePath(Routes.History)}>
        <BackToTableButton />
      </Link>
      <ContentContainer>
        <ContentCard>
          {!data ? (
            <Spinner inline />
          ) : (
            <Row>
              <Col md={6}>
                <HistoryDetailsChecklist id={id} />
              </Col>
              <Col md={6}>
                <h6 className="text-uppercase">{getIntl('Asset info')}</h6>
                <hr className="mt-0" />
                <StyledContent>
                  <div>{getIntl('Date')}:</div>
                  <div>{dateFormat(data.date)}</div>
                  <div>{getIntl('Asset category')}:</div>
                  <div>
                    {getIntl(data.assetCategoryName)} ({data.assetCategory})
                  </div>
                  <div>{getIntl('Asset code')}:</div>
                  <div>{data.assetCode}</div>
                  <div>{getIntl('User name')}:</div>
                  <div>{data.userName}</div>
                </StyledContent>

                <h6 className="text-uppercase mt-5">{getIntl('Asset changes')}</h6>
                <hr className="mt-0" />
                {Boolean(Object.keys(diff).length) ? (
                  <StyledContent>
                    {Object.entries(diff).map(([key, value]: [string, any]) => (
                      <React.Fragment key={key}>
                        <div>{key}:</div>
                        <div>
                          <s>{value.from.toString() ?? '-'}</s> &#8594; {value.to.toString() ?? '-'}
                        </div>
                      </React.Fragment>
                    ))}
                  </StyledContent>
                ) : (
                  <Alert variant="light" className="mb-0 text-center">
                    {getIntl('There is no difference for selected asset')}
                  </Alert>
                )}
              </Col>
            </Row>
          )}
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  row-gap: 2px;

  > div:nth-child(2n) {
    text-align: right;
  }
`;

export default ViewHistoryDetails;
