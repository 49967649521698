import React, { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DataTableTemplate, ContentContainer, ContentCard, Checkbox } from 'components/_common';
import ChartGlobalDevelopment from './ChartGlobalDevelopment';
import { YAxisTypes } from 'constants/index';

const ViewGlobalDevelopment: React.FC = () => {
  const [yAxisType, setYAxisType] = useState<YAxisTypes>(YAxisTypes.LINEAR);

  const handleYAxisTypeSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setYAxisType(event.currentTarget.checked ? YAxisTypes.LOGARITHMIC : YAxisTypes.LINEAR);
  }, []);

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs="auto">
          <Checkbox
            labelKey="Logarithmic axis"
            className="icheck-primary"
            checked={yAxisType === 'logarithmic'}
            onChange={handleYAxisTypeSwitch}
          />
        </Col>
      </Row>
      <ContentContainer>
        <ContentCard>
          <ChartGlobalDevelopment yAxisType={yAxisType} />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

export default ViewGlobalDevelopment;
