import React from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { generatePath } from 'react-router';
// import { externalTimeSystemSelector } from 'modules/app/selectors';
import { isMaterialsEnabledSelector } from 'modules/app/selectors';
import {
  // TasksNewTable,
  TasksComplaintsTable,
  TasksMobileSubmittedTable,
  TasksUpcomingTable,
  TasksTable,
  TasksFinishedTable,
} from 'components/_tables';
import TableChecklists from './TableChecklists';
import TableMaterials from './TableMaterials';
import { Tabs, Tab } from 'components/_common';
import { Routes } from 'constants/index';

const TasksTabs: React.FC = () => {
  const { getIntl } = useLocale();
  // const externalTimeSystem: boolean = useSelector(externalTimeSystemSelector);
  const maxTableHeight = 'calc(100vh - 260px)';
  const isMaterialsEnabled = useSelector(isMaterialsEnabledSelector);

  return (
    <Tabs mountOnEnter unmountOnExit>
      {/* {externalTimeSystem && (
        <Tab id="tab_tasks_new" eventKey={`${generatePath(Routes.Tasks)}${Routes.HASH.New}`} title={getIntl('New')}>
          <TasksNewTable maxTableHeight={maxTableHeight} />
        </Tab>
      )} */}
      <Tab
        id="tab_tasks_complaints"
        eventKey={`${generatePath(Routes.Tasks)}${Routes.HASH.Complaints}`}
        title={getIntl('Complaints')}
      >
        <TasksComplaintsTable maxTableHeight={maxTableHeight} />
      </Tab>
      <Tab
        id="tab_tasks_mobile_submitted"
        eventKey={`${generatePath(Routes.Tasks)}${Routes.HASH.MobileSubmitted}`}
        title={getIntl('Mobile submitted')}
      >
        <TasksMobileSubmittedTable maxTableHeight={maxTableHeight} />
      </Tab>
      <Tab
        id="tab_tasks_upcoming"
        eventKey={`${generatePath(Routes.Tasks)}${Routes.HASH.Upcoming}`}
        title={getIntl('Upcoming')}
      >
        <TasksUpcomingTable maxTableHeight={maxTableHeight} />
      </Tab>
      <Tab
        id="tab_tasks_not_started_ongoing"
        eventKey={generatePath(Routes.Tasks)}
        title={getIntl('Not started/Ongoing')}
      >
        <TasksTable maxTableHeight={maxTableHeight} />
      </Tab>
      <Tab
        id="tab_tasks_finished"
        eventKey={`${generatePath(Routes.Tasks)}${Routes.HASH.Finished}`}
        title={getIntl('Finished')}
      >
        <TasksFinishedTable maxTableHeight={maxTableHeight} />
      </Tab>
      <Tab
        id="tab_tasks_checklists"
        eventKey={`${generatePath(Routes.Tasks)}${Routes.HASH.Checklists}`}
        title={getIntl('Checklists')}
      >
        <TableChecklists maxTableHeight={maxTableHeight} />
      </Tab>
      {isMaterialsEnabled && (
        <Tab
          id="tab_tasks_materials"
          eventKey={`${generatePath(Routes.Tasks)}${Routes.HASH.Materials}`}
          title={getIntl('Materials')}
        >
          <TableMaterials maxTableHeight={maxTableHeight} />
        </Tab>
      )}
    </Tabs>
  );
};

export default TasksTabs;
