import styled from 'styled-components';
import React, { useMemo, useCallback, useState } from 'react';
import { useLocale } from 'hooks';
import { useReadableTable } from 'hooks';
import { Alert, OverlayTriggerTooltip } from 'components/_common';
import TextOverflow from './TextOverflow';
import { isObject } from '@utiligize/shared/utils';
import { IconList } from '@utiligize/shared/resources';

interface Props {
  assetMeta: Asset.AssetMeta;
}

enum InfoTypes {
  MISC = 'misc',
  BASIC = 'basic',
}

const AssetDetails: React.FC<Props> = ({ assetMeta }) => {
  const { getIntl } = useLocale();
  const { formatProp, formatValue } = useReadableTable();
  const [infoType, setInfoType] = useState<InfoTypes>(
    isObject(assetMeta?.[InfoTypes.MISC]) ? InfoTypes.MISC : InfoTypes.BASIC
  );

  const infoTypes = useMemo(() => Object.values(InfoTypes), []);

  const InfoTypeLocalesMap = useMemo(
    () => ({
      [InfoTypes.MISC]: getIntl('Misc information'),
      [InfoTypes.BASIC]: getIntl('Basic information'),
    }),
    [getIntl]
  );

  // Note: properties without null values
  const properties = useMemo(
    () =>
      Object.fromEntries(
        // Note. misc object returned as an array protection.
        Object.entries((isObject(assetMeta?.[infoType]) && assetMeta?.[infoType]) ?? {})
          .filter(([key, value]) => value !== null && !['misc', 'replacement_year'].includes(key))
          .map(([key, value]) => [
            formatProp(key, { translate: false }),
            formatValue(key, value as string | number | boolean, { translate: false }),
          ])
      ),
    [infoType, assetMeta, formatProp, formatValue]
  );

  const handleInfoIconClick = useCallback((event: React.MouseEvent) => {
    const nextInfoType = event.currentTarget.getAttribute('data-type') as InfoTypes;
    setInfoType(nextInfoType);
  }, []);

  return (
    <StyledContainer>
      <StyledHeader>
        {infoTypes
          .filter(type => Boolean(assetMeta[type]))
          .map((type: InfoTypes) => (
            <OverlayTriggerTooltip
              key={type}
              placement="top"
              overlayId={type}
              overlayChildren={InfoTypeLocalesMap[type]}
            >
              <StyledIconList
                width={18}
                height={18}
                $active={infoType === type}
                data-type={type}
                onClick={handleInfoIconClick}
              />
            </OverlayTriggerTooltip>
          ))}
      </StyledHeader>
      {Boolean(Object.keys(properties).length) ? (
        <StyledContent>
          {Object.entries(properties).map(([key, value]: [string, any]) => (
            <React.Fragment key={key}>
              <TextOverflow data-text={key}>{key}:</TextOverflow>
              <TextOverflow data-text={formatValue(key, value)}>{value}</TextOverflow>
            </React.Fragment>
          ))}
        </StyledContent>
      ) : (
        <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  font-size: 12px;
`;

const StyledHeader = styled.div`
  margin-bottom: 7.5px;
`;

const StyledIconList = styled(IconList)<{ $active: boolean }>`
  fill: ${({ $active }) => ($active ? 'var(--map-active-color)' : 'var(--map-text-color-inactive)')};

  &:hover {
    cursor: pointer;
    fill: var(--map-active-color);
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const StyledContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  row-gap: 2px;

  > div:nth-child(2n) {
    text-align: right;
  }
`;

export default AssetDetails;
