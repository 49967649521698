import { createSelector } from 'reselect';
import { percentilesOptionsSelector, scenariosYearsOptionsSelector } from 'modules/options/selectors';
import { simulationIdSelector } from 'modules/layouts/selectors';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.networkLoading;

export const scenarioRunInfoSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): NetworkLoading.Root['scenarioRunInfo'] => networkLoading.scenarioRunInfo
);

// ------------------------------------
// Transformers
// ------------------------------------

export const transformersCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.transformersCount
);

const transformersHashSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): Type.Hash<NetworkLoading.Transformer> => networkLoading.transformersHash
);

export const transformersSelector = createSelector(
  transformersHashSelector,
  (transformersHash: Type.Hash<NetworkLoading.Transformer>): NetworkLoading.Transformer[] =>
    Object.values(transformersHash)
);

export const transformerSelectorFactory = (uuid: string | null) =>
  createSelector(
    transformersHashSelector,
    (transformersHash: Type.Hash<NetworkLoading.Transformer>): NetworkLoading.Transformer =>
      transformersHash[uuid || '']
  );

export const tableTransformersCablesWaitForDependenciesSelector = createSelector(
  [scenariosYearsOptionsSelector, percentilesOptionsSelector, simulationIdSelector],
  (scenariosYearsOptions, percentilesOptions, simulationId): boolean => {
    return Boolean(!scenariosYearsOptions || !percentilesOptions || !simulationId);
  }
);

// ------------------------------------
// Cables
// ------------------------------------

export const cablesCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.cablesCount
);

const cablesHashSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): Type.Hash<NetworkLoading.Cable> => networkLoading.cablesHash
);

export const cablesSelector = createSelector(
  cablesHashSelector,
  (cablesHash: Type.Hash<NetworkLoading.Cable>): NetworkLoading.Cable[] => Object.values(cablesHash)
);

export const cableSelectorFactory = (uuid: string | null) =>
  createSelector(
    cablesHashSelector,
    (cablesHash: Type.Hash<NetworkLoading.Cable>): NetworkLoading.Cable => cablesHash[uuid || '']
  );

// ------------------------------------
// Customers
// ------------------------------------

const customersTypesHashSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): NetworkLoading.Root['customersTypesHash'] => networkLoading.customersTypesHash
);

export const customersTypesSelector = createSelector(
  customersTypesHashSelector,
  (customersTypesHash: NetworkLoading.Root['customersTypesHash']): NetworkLoading.CustomersTypes[] =>
    Object.values(customersTypesHash)
);

export const customersTypesInstallationsSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): NetworkLoading.Root['customersTypesInstallations'] =>
    networkLoading.customersTypesInstallations
);

// ------------------------------------
// Comments
// ------------------------------------

export const ignoreMessagesCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.ignoreMessagesCount
);

const ignoreMessagesHashSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): Type.Hash<NetworkLoading.IgnoreMessages> => networkLoading.ignoreMessagesHash
);

export const ignoreMessagesSelector = createSelector(
  ignoreMessagesHashSelector,
  (ignoreMessagesHash: Type.Hash<NetworkLoading.IgnoreMessages>): NetworkLoading.IgnoreMessages[] =>
    Object.values(ignoreMessagesHash)
);

export const futureChartDataHashSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): NetworkLoading.Root['futureChartDataHash'] =>
    networkLoading.futureChartDataHash
);

export const futureChartDataSelectorFactory = (storeKey: string, index: number) =>
  createSelector(
    futureChartDataHashSelector,
    (futureChartDataHash: NetworkLoading.Root['futureChartDataHash']): NetworkLoading.FutureChartData =>
      futureChartDataHash?.[storeKey]?.[index]
  );
