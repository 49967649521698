import styled from 'styled-components';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { FormikHandlers, FormikHelpers } from 'formik';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { materialsHashSelector } from 'modules/tasks/selectors';
import { Button, FormInput } from 'components/_common';
import SelectTaskMaterials from './SelectTaskMaterials';
import { IconPlus, IconDelete } from '@utiligize/shared/resources';

interface Props {
  touched: any;
  errors: any;
  materials: Tasks.CreateTaskActionProps['materials'];
  setFieldValue: FormikHelpers<Tasks.CreateTaskActionProps>['setFieldValue'];
  handleBlur: FormikHandlers['handleBlur'];
}

const SectionMaterials: React.FC<Props> = ({ touched, errors, materials, setFieldValue, handleBlur }) => {
  const [option, setOption] = useState<Type.SelectOption<number> | null>(null);
  const materialsHash = useSelector(materialsHashSelector);

  const handleAddButtonClick = useCallback(() => {
    if (!option) return;
    setFieldValue('materials', materials.concat({ id: option.value, quantity: '1' }));
  }, [materials, setFieldValue, option]);

  const handleDeleteButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const id = Number(event.currentTarget.getAttribute('data-id'));
      setFieldValue(
        'materials',
        materials.filter(m => m.id !== id)
      );
    },
    [materials, setFieldValue]
  );

  const handleQuantityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = Number(event.currentTarget.getAttribute('data-id'));
      const quantity = event.currentTarget.value;
      setFieldValue(
        'materials',
        materials.map(m => (m.id === id ? { ...m, quantity } : m))
      );
    },
    [materials, setFieldValue]
  );

  const isAddButtonDisabled = useMemo(() => !option || materials.some(m => m.id === option.value), [option, materials]);

  return (
    <>
      <StyledQuestionInputContainer
        className={classNames({ 'is-invalid': Boolean(touched.materials && errors.materials) })}
      >
        <div>
          <SelectTaskMaterials value={option?.value || null} onChange={setOption} />
        </div>
        <Button icon={<IconPlus />} disabled={isAddButtonDisabled} onClick={handleAddButtonClick} marginLeft />
      </StyledQuestionInputContainer>
      <StyledListGroup>
        {materials.map((material, index) => (
          <ListGroup.Item key={material.id}>
            <Row className="align-items-center">
              <Col>
                {materialsHash[`_${material.id}_`]?.name || material.id}{' '}
                <small className="text-nowrap">({materialsHash[`_${material.id}_`]?.unit})</small>
              </Col>
              <Col xs="auto">
                <FormInput
                  labelKey=""
                  min={0}
                  type="number"
                  name={`materials[${index}].quantity`}
                  data-id={material.id}
                  value={material.quantity!}
                  onChange={handleQuantityChange}
                  onBlur={handleBlur}
                  isNew
                  errorKey={
                    Boolean(touched.materials?.[index]?.quantity && errors.materials?.[index]?.quantity)
                      ? (errors.materials?.[index]?.quantity as string)
                      : ''
                  }
                />
              </Col>
              <Col xs="auto">
                <Button icon={<IconDelete />} data-id={material.id} onClick={handleDeleteButtonClick} size="small" />
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </StyledListGroup>
    </>
  );
};

const StyledQuestionInputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;

  > div:first-child {
    flex-grow: 1;
  }
`;

const StyledListGroup = styled(ListGroup)`
  margin-top: 0.5rem;
  border-radius: 8px;

  > div {
    padding: 0.5rem 1rem;
  }
`;

export default SectionMaterials;
