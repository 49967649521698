import { handleActions, combineActions } from 'redux-actions';
import {
  fetchTasksAction,
  fetchTasksByUUIDCodeAction,
  deleteTaskAction,
  createTaskAction,
  editTaskAction,
  fetchTaskDetailsAction,
  setTaskDetailsAction,
  reopenTaskAction,
  deleteTaskAttachmentsAction,
  fetchTasksDepartmentsAction,
} from './tasks.actions';
import {
  fetchUsersRegisteredHoursAction,
  fetchTasksRegisteredHoursAction,
  resetReduxStateAction,
} from './registered-hours.actions';
import {
  fetchTaskChecklistAction,
  updateTaskChecklistAction,
  fetchTaskChecklistsAction,
  createTaskChecklistAction,
  editTaskChecklistAction,
  deleteTaskChecklistAction,
} from './checklists.actions';
import {
  fetchTaskMaterialsAction,
  createTaskMaterialAction,
  editTaskMaterialAction,
  deleteTaskMaterialAction,
  fetchMaterialsByTaskIDAction,
} from './materials.actions';
import {
  fetchRepeatedTasksSectionsAction,
  createRepeatedTasksSectionAction,
  editRepeatedTasksSectionAction,
  deleteRepeatedTasksSectionAction,
} from './repeated-tasks-sections.actions';
import {
  repeatedTasksLoadingAction,
  fetchRepeatedTasksAction,
  createRepeatedTaskAction,
  editRepeatedTaskAction,
  deleteRepeatedTaskAction,
} from './repeated-tasks.actions';
import { fetchTotalHoursAction } from './total-hours.actions';
import { fetchNewTasksAction, deleteNewTaskAction } from './new-tasks.actions';
import { fetchTasksComplaintsAction, deleteTasksComplaintAction } from './tasks-complaints.actions';

const initialState: Tasks.Root = {
  newTasksCount: 0,
  newTasksHash: {} as Type.Hash<Tasks.NewTask>,

  tasksComplaintsCount: 0,
  tasksComplaintsHash: {} as Type.Hash<Tasks.TaskComplaint>,

  mobileSubmittedTasksCount: 0,
  mobileSubmittedTasksHash: {} as Type.Hash<Tasks.Task>,

  upcomingTasksCount: 0,
  upcomingTasksHash: {} as Type.Hash<Tasks.Task>,

  tasksCount: 0,
  tasksHash: {} as Type.Hash<Tasks.Task>,
  taskDetails: null,

  finishedTasksCount: 0,
  finishedTasksHash: {} as Type.Hash<Tasks.Task>,

  repeatedTasksSectionsCount: 0,
  repeatedTasksSectionsHash: {} as Type.Hash<Tasks.RepeatedTasksSection>,

  repeatedTasksFetched: false,
  repeatedTasksLoading: false,
  repeatedTasksHash: {} as Type.Hash<Tasks.RepeatedTask>,

  checklistsCount: 0,
  checklistsHash: {} as Type.Hash<Tasks.Checklist>,

  materialsCount: 0,
  materialsHash: {} as Type.Hash<Tasks.Material>,

  totalHours: [],
  totalHoursAmount: 0,
  employeeHoursAvailable: 0,

  taskMaterialsCount: 0,
  taskMaterials: [],

  tasksDepartmentsHash: {} as Type.Hash<Tasks.Department>,
  tasksDepartmentsFetched: null,

  usersRegisteredHoursHash: {} as Type.Hash<Tasks.UserRegisteredHours>,
  usersRegisteredHoursCount: 0,
  tasksRegisteredHoursHash: {} as Type.Hash<Tasks.TaskRegisteredHours>,
  tasksRegisteredHoursCount: 0,

  tasksByUUIDCode: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [repeatedTasksLoadingAction.toString()]: (state: Tasks.Root): Tasks.Root => ({
    ...state,
    repeatedTasksLoading: true,
  }),

  [combineActions(
    fetchTasksAction,
    fetchTaskDetailsAction,
    setTaskDetailsAction,
    fetchTasksByUUIDCodeAction,
    fetchRepeatedTasksSectionsAction,
    fetchRepeatedTasksAction,
    fetchNewTasksAction,
    createRepeatedTaskAction,
    editRepeatedTaskAction,
    deleteRepeatedTaskAction,
    fetchTaskChecklistsAction,
    fetchTaskMaterialsAction,
    fetchMaterialsByTaskIDAction,
    fetchTotalHoursAction,
    fetchTasksComplaintsAction,
    fetchUsersRegisteredHoursAction,
    fetchTasksRegisteredHoursAction,
    resetReduxStateAction
  ) as any]: {
    next: (
      state: Tasks.Root,
      action: Shared.ReduxAction<Partial<Tasks.Root> & { skipStoreUpdate: true | undefined }>
    ): Tasks.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  // has own reducer to handle 3 different states: null - wasn't called, true - success, false - failed
  [fetchTasksDepartmentsAction.toString()]: {
    next: (
      state: Tasks.Root,
      action: Shared.ReduxAction<Pick<Tasks.Root, 'tasksDepartmentsHash' | 'tasksDepartmentsFetched'>>
    ): Tasks.Root => ({ ...state, ...action.payload }),
    throw: (state: Tasks.Root) => ({ ...state, tasksDepartmentsFetched: false }),
  },
};

export {
  fetchTasksAction,
  fetchTasksByUUIDCodeAction,
  fetchNewTasksAction,
  deleteNewTaskAction,
  deleteTaskAction,
  createTaskAction,
  editTaskAction,
  fetchRepeatedTasksSectionsAction,
  createRepeatedTasksSectionAction,
  editRepeatedTasksSectionAction,
  deleteRepeatedTasksSectionAction,
  fetchRepeatedTasksAction,
  createRepeatedTaskAction,
  editRepeatedTaskAction,
  deleteRepeatedTaskAction,
  fetchTaskChecklistsAction,
  createTaskChecklistAction,
  editTaskChecklistAction,
  deleteTaskChecklistAction,
  fetchTaskMaterialsAction,
  createTaskMaterialAction,
  editTaskMaterialAction,
  deleteTaskMaterialAction,
  fetchMaterialsByTaskIDAction,
  fetchTaskDetailsAction,
  setTaskDetailsAction,
  fetchTaskChecklistAction,
  updateTaskChecklistAction,
  fetchTotalHoursAction,
  fetchTasksComplaintsAction,
  deleteTasksComplaintAction,
  reopenTaskAction,
  deleteTaskAttachmentsAction,
  fetchTasksDepartmentsAction,
  fetchUsersRegisteredHoursAction,
  fetchTasksRegisteredHoursAction,
};

export * from './service-time.actions';
export * from './comments.actions';

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
