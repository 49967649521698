import { createAction } from 'redux-actions';
import { portfolioIdSelector, scenarioIdSelector, selectedChartYearSelector } from './selectors';
import { DERsPreviewYearsHashSelector } from 'modules/newLoad/selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const setPaginationAction = createAction('layouts/SET_PAGINATION');

export const setModalConfirmationMetaAction = createAction('layouts/SET_MODAL_DELETE_CONFIRMATION_META');

export const toggleSidebarAction = createAction('layouts/TOGGLE_SIDEBAR');

export const closeSidebarAction = createAction('layouts/CLOSE_SIDEBAR');

export const setErrorToastAction = createAction('layouts/SET_ERROR_TOAST');

export const setInfoToastAction = createAction('layouts/SET_INFO_TOAST');

export const setSuccessToastAction = createAction('layouts/SET_SUCCESS_TOAST');

export const deleteToastAction = createAction('layouts/DELETE_TOAST');

export const toggleIsSidebarHelpMenuOpen = createAction('layouts/TOGGLE_IS_SIDEBAR_HELP_MENU_OPEN');

export const setLayoutAction = createAction(
  'layouts/SET_LAYOUT_MODIFIER',
  (payload: Partial<Layouts.Root>) => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    if (!payload.portfolioId && !payload.scenarioIdHash && !payload.selectedChartYear) return payload;
    // Handler for SelectPortfolio or SelectScenarioType dropdowns
    const state = getState();
    const portfolioId = payload.portfolioId || portfolioIdSelector(state);
    const scenarioId = payload.scenarioIdHash?.[portfolioId!] || scenarioIdSelector(state);
    const value = payload.selectedChartYear || selectedChartYearSelector(state);
    const DERsPreviewYearsHash = DERsPreviewYearsHashSelector(state);
    const years = DERsPreviewYearsHash[`${portfolioId}_${scenarioId}`];

    if (!years || years.includes(value)) return payload;
    // Note. (DERsPreviewYears) Automatically apply default current year value or first available value if doesn't exist
    const currentYear = new Date().getFullYear();
    return { ...payload, selectedChartYear: years.includes(currentYear) ? currentYear : years[0] };
  }
);

export const setFeatureToggleAction =
  createAction<Partial<Layouts.Root['featureToggles']>>('layouts/SET_FEATURE_TOGGLE');
