import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { RouteType } from 'constants/index';
import { settingsSelector, n1RouteSelector, selectedN1RoutesIdsSelector } from 'modules/map/selectors';
import { setMapStateAction } from 'modules/map';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import Section from 'components/Map/common/Section';
import ControlBox from 'components/Map/common/ControlBox';
import { Button } from 'components/_common';

const ForecastInvestmentN1Routes: React.FC<{ isN1Enabled: boolean }> = ({ isN1Enabled }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const settings = useSelector(settingsSelector);
  const n1Route = useSelector(n1RouteSelector);
  const selectedN1RoutesIds = useSelector(selectedN1RoutesIdsSelector);
  const history = useHistory<{ n1Route: N1.Item['positionOnTheMap'] }>();

  const handleRouteChangeClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = Number(e.currentTarget.getAttribute('data-id'));
      const next = e.target.checked ? [...selectedN1RoutesIds, id] : selectedN1RoutesIds.filter(i => i !== id);
      dispatch(setMapStateAction({ selectedN1RoutesIds: next }));
    },
    [dispatch, selectedN1RoutesIds]
  );

  const handleRouteMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const highlightedN1RouteId = Number(e.currentTarget.getAttribute('data-id'));
      dispatch(setMapStateAction({ highlightedN1RouteId }));
    },
    [dispatch]
  );

  const handleRouteMouseLeave = useCallback(
    () => dispatch(setMapStateAction({ highlightedN1RouteId: null })),
    [dispatch]
  );

  const handleShowAllRoutesButtonClick = useCallback(() => {
    dispatch(setMapStateAction({ n1Route: null, selectedN1RoutesIds: [] }));
    // TableN1 - handleMapLinkClick - location.state cleanup
    history.replace(history.location.pathname);
  }, [dispatch, history]);

  return (
    <CollapseGroup
      id="n_1"
      dataMarker="panel_n_1_routes"
      titleKey={settings.themeTitles?.n_1!}
      stateLoc="theme"
      disabled={settings.isN1ThemeDisabled || !isN1Enabled}
    >
      <Section
        className="mt-2"
        title={(n1Route ? getIntl('Routes for') : getIntl('Routes')) + (n1Route ? ` ${n1Route?.target}` : '')}
      >
        {Boolean(n1Route?.routes) ? (
          n1Route?.routes?.map(route => {
            const isChecked = selectedN1RoutesIds.includes(route.id);
            return (
              <div
                key={route.id}
                data-id={route.id}
                className="position-relative"
                onMouseOver={handleRouteMouseOver}
                onMouseLeave={handleRouteMouseLeave}
              >
                <ControlBox
                  key={route.id}
                  data-id={route.id}
                  type="checkbox"
                  checked={isChecked}
                  labelKey={route.name}
                  onChange={handleRouteChangeClick}
                  disabled={isChecked && selectedN1RoutesIds.length <= 1}
                />
                <i
                  className={classNames(
                    'legend-icon',
                    route.type === RouteType.PRIMARY ? 'island_networks' : 'meshed_structure'
                  )}
                />
              </div>
            );
          })
        ) : (
          <StyledInfo>
            {getIntl('Select an asset to get its routes displayed')}
            .&nbsp;
            {getIntl('Right click on asset will draw its routes')}.
          </StyledInfo>
        )}
      </Section>
      {Boolean(n1Route) && (
        <StyledWarning>
          <p>{getIntl('Currently, only selected objects are shown')}</p>
          <Button labelKey="Show all objects" onClick={handleShowAllRoutesButtonClick} size="small" />
        </StyledWarning>
      )}
    </CollapseGroup>
  );
};

const StyledInfo = styled.div`
  font-weight: 400;
  letter-spacing: 0.04em;
  font-size: 11px;
  color: var(--map-active-color);
  padding: 3px;
`;

const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  > p {
    color: var(--map-text-color-secondary-light);
    margin: 0 10px 0 0;
  }
`;

export default ForecastInvestmentN1Routes;
