import React from 'react';
import { DataTableTemplate } from 'components/_common';
import ModalChecklists from './ModalChecklists';
import TableChecklists from './TableChecklists';

const ViewChecklists: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <ModalChecklists />
    <TableChecklists />
  </DataTableTemplate>
);

export default ViewChecklists;
