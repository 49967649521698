import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { customersTypesSelector, customersTypesInstallationsSelector } from 'modules/networkLoading/selectors';
import { fetchAssetCustomersAction } from 'modules/networkLoading';
import { updatedChartYearSelector } from 'modules/newLoad/selectors';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Alert, SelectDERsPreviewYear, Spinner } from 'components/_common';
import ContainerTopControls from './ContainerTopControls';
import Card from './Card';

interface Props {
  uuid: string;
}

const TabCustomers: React.FC<Props> = ({ uuid }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const items = useSelector(customersTypesSelector);
  const installations = useSelector(customersTypesInstallationsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const year = useSelector(updatedChartYearSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId || !year) return;
    setLoading(true);
    dispatch(fetchAssetCustomersAction({ portfolioId, uuid, scenarioId, year })).finally(() => setLoading(false));
  }, [dispatch, portfolioId, uuid, scenarioId, year]);

  const handleSelectYearChange = useCallback(
    (selectedChartYear: number) => dispatch(setLayoutAction({ selectedChartYear })),
    [dispatch]
  );

  if (loading) return <Spinner isInFullHeightContainer />;
  if (!items.length)
    return <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>;

  return (
    <>
      <ContainerTopControls>
        <SelectDERsPreviewYear
          labelKey=""
          value={year}
          onChange={handleSelectYearChange}
          variant="small"
          isSearchable={false}
        />
      </ContainerTopControls>
      {items.map((item: NetworkLoading.CustomersTypes, index: number) => (
        <Card key={item.id} marginBottom={items.length - 1 !== index || !!installations.length}>
          <Row>
            <Col>
              <label>{getIntl('Type')}:</label>
              <span>{item.Type}</span>
            </Col>
            <Col>
              <label>{getIntl('Number')}:</label>
              <span>{item.Number}</span>
            </Col>
          </Row>
        </Card>
      ))}
      {installations?.length && (
        <Card>
          <label>{getIntl('Installations')}:</label>
          <ul>
            {installations.flat().map(i => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </Card>
      )}
    </>
  );
};

export default TabCustomers;
