import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.customers;

export const rightsCountSelector = createSelector(
  rootSelector,
  (customers: Customers.Root): number => customers.rightsCount
);

export const rightsSelector = createSelector(
  rootSelector,
  (customers: Customers.Root): Customers.Root['rights'] => customers.rights
);

export const existingDERsOptionsSelector = createSelector(
  rootSelector,
  (customers: Customers.Root): Customers.Root['existingDERsOptions'] => customers.existingDERsOptions
);
