import { createAction } from 'redux-actions';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { isPortfolioLoadflowEnabledSelector } from 'modules/options/selectors';
import { AssetLifeAPI, PaginationType } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchRightsAction: any = createAction(
  'customers/FETCH_RIGHTS',
  async (
    { skipPagination, skipStoreUpdate }: { skipPagination: boolean; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Customers.Root, 'rights' | 'rightsCount'>> => {
      const state: State.Root = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve({ rightsCount: 0, rights: [] });
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.CUSTOMERS_RIGHTS
      )(state);
      return AssetLifeAPI.get('customers/customer_rights', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          percentile: filters?.percentile,
          load_type: filters?.loadType,
          hide_solved: filters?.solvedValue,
          outliers_removed: filters?.outliersRemoved,
          missing_type: filters?.missingType,
          existing_ders_id: filters?.existingDersId,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
        },
      }).then((res: any) => ({
        rightsCount: res.data.count,
        rights: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const fetchExistingDERsOptionsAction = createAction(
  'customers/FETCH_EXISTING_DERS_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Customers.Root, 'existingDERsOptions'>> => {
      const state: State.Root = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve({ existingDERsOptions: [] });
      return AssetLifeAPI.get('/customers/customer_existing_ders', {
        params: { portfolio_id: portfolioIdSelector(state) },
      }).then(res => ({
        existingDERsOptions: res.data.map((i: { id: number; labels: string }) => ({
          value: i.id,
          label: i.labels,
        })),
      }));
    }
);
