import React, { useMemo, useCallback } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { TaskMonths } from 'constants/tasks';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  months: Tasks.TaskMonthsEnum[];
  onChange: (value: Tasks.TaskMonthsEnum[]) => void;
}

const SelectMonth: React.FC<Props> = ({
  isDisabled = false,
  isMulti,
  labelKey = 'Months',
  months = [],
  onChange,
  ...props
}) => {
  const { getIntl } = useLocale();

  const { options, value } = useMemo(
    () =>
      TaskMonths.reduce(
        (acc: any, month: Tasks.TaskMonthsEnum) => {
          const item = { value: month, label: getIntl(month) };
          if (months.includes(month)) acc.value.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], value: [] }
      ),
    [months, getIntl]
  );

  const handleSelectChange = useCallback(
    (months: Type.SelectOption[]) => {
      onChange(
        isMulti
          ? months
              ?.sort((a, b) => (TaskMonths as any).indexOf(a.value) - (TaskMonths as any).indexOf(b.value))
              .map(i => i.value) || []
          : [(months as any)?.value]
      );
    },
    [isMulti, onChange]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      isDisabled={isDisabled}
      value={isMulti ? value : value?.[0] || null}
      options={options}
      onChange={handleSelectChange}
      isMulti={isMulti}
      placeholderKey="Select month"
    />
  );
};

export default SelectMonth;
