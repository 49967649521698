import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { getDERProfileChartData } from 'modules/newLoad';
import Chart, { Colors, getBaseOptions } from 'components/_charts/Chart';
import { _omit } from '@utiligize/shared/utils';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  series: Shared.SeriesOptionsType[];
}

interface DataItem {
  timestamp: number;
  power: number;
}

interface Response {
  title: string;
  ts_data: DataItem[];
  xlabel: string;
  ylabel: string;
}

const transformChartData = (action: Shared.ReduxAction<Response>): ChartDataProps | null => {
  if (!Object.keys(action.payload || {}).length) return null;

  const seriesHash = action.payload.ts_data?.reduce((acc: any, item: DataItem) => {
    const keys = Object.keys(_omit(action.payload.ts_data[0] || {}, 'timestamp'));
    keys.forEach((key, index) => {
      const elem = [item.timestamp * 1000, Number(item[key as keyof DataItem])];
      if (!acc[key]) {
        acc[key] = { name: key, data: [elem], color: Colors[index], lineWidth: 0.5 };
      } else {
        acc[key].data.push(elem);
      }
    });
    return acc;
  }, {});

  return {
    title: action.payload.title,
    xAxisTitle: action.payload.xlabel,
    yAxisTitle: action.payload.ylabel,
    series: Object.values<Shared.SeriesOptionsType>(seriesHash || {}),
  };
};

const ChartDERProfile: React.FC<{ id: number }> = ({ id }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const baseOptions = getBaseOptions(getIntl, chartData);

  useEffect(() => {
    setChartData(null);
    if (!id) return;
    dispatch(getDERProfileChartData({ id }))
      .then(action => transformChartData(action))
      .then(setChartData)
      .catch(console.error);
  }, [dispatch, id, getIntl]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      xAxis: {
        type: 'datetime',
        ...baseOptions.xAxis,
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    }),
    [chartData, getIntl, baseOptions]
  ) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_der_profile" />;
};

export default ChartDERProfile;
