import React, { useMemo, useEffect } from 'react';
import { Switch, Route } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScenarioOptionsAction, fetchSimulationOptionsAction } from 'modules/options';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import {
  portfolioOptionsSelector,
  scenarioOptionsHashSelector,
  simulationOptionsHashSelector,
} from 'modules/options/selectors';
import { PageAppExceptions } from 'pages';
import { ViewAnalysisTool } from 'components/AnalysisTool';
import { Spinner } from 'components/_common';
import { Routes } from 'constants/index';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageAnalysisTool: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);
  const simulationOptionsHash = useSelector(simulationOptionsHashSelector);

  const allScenariosFetched = useMemo(() => {
    if (!portfolioOptions?.length) return false;
    return portfolioOptions.every(portfolio => scenarioOptionsHash[portfolio.value]);
  }, [scenarioOptionsHash, portfolioOptions]);

  /* fetch scenario options for all portfolios except current */
  const portfolioOptionsIds = useMemo(
    () => portfolioOptions?.map(option => option.value).filter(value => value !== portfolioId) || [],
    [portfolioOptions, portfolioId]
  );

  useEffect(() => {
    if (!portfolioOptionsIds.length) return;
    portfolioOptionsIds.forEach(id => dispatch(fetchScenarioOptionsAction(id)));
  }, [portfolioOptionsIds, dispatch]);
  /* end */

  /* create portfolio scenario map, fetch simulations for each combination scenario */
  const portfolioScenarioMap = useMemo(() => {
    return portfolioOptions?.flatMap(portfolio =>
      (scenarioOptionsHash?.[portfolio.value] || [])
        .filter(s => s.value !== scenarioId)
        .map(scenario => ({
          portfolioId: portfolio.value,
          scenarioId: scenario.value,
        }))
    );
  }, [portfolioOptions, scenarioOptionsHash, scenarioId]);

  useEffect(() => {
    if (!allScenariosFetched || !portfolioScenarioMap?.length) return;
    portfolioScenarioMap.forEach(({ portfolioId, scenarioId }) =>
      dispatch(fetchSimulationOptionsAction(portfolioId, scenarioId))
    );
  }, [allScenariosFetched, portfolioScenarioMap, dispatch]);
  /* end */

  const allSimulationsFetched = useMemo(
    () =>
      portfolioScenarioMap?.every(
        ({ portfolioId, scenarioId }) => simulationOptionsHash[`${portfolioId}_${scenarioId}`]
      ),
    [simulationOptionsHash, portfolioScenarioMap]
  );

  const renderContent = () => {
    if (!allScenariosFetched || !allSimulationsFetched) return <Spinner isInFullHeightContainer />;
    return (
      <Switch>
        <Route exact path={Routes.AnalysisTool} component={ViewAnalysisTool} />
        <Route path="*" component={PageAppExceptions} />
      </Switch>
    );
  };

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isAnalysisToolEnabled}>
      <>{renderContent()}</>
    </PagePermissionsProvider>
  );
};

export default PageAnalysisTool;
