import { createAction } from 'redux-actions';
import { AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const getCO2EmissionChartData: any = createAction(
  'co2e/GET_SAVING_OVER_TIME_CHART_DATA',
  async ({
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    isCO2eCumulativeOverview,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    simulationId: Layouts.Root['simulationId'];
    versionId: number;
    isCO2eCumulativeOverview: Layouts.Root['isCO2eCumulativeOverview'];
  }) =>
    (): Promise<any> => {
      return AssetLifeAPI.get('scenarios/co2e_dashboard_data', {
        params: {
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          version_id: versionId,
          cumulative: isCO2eCumulativeOverview,
        },
      }).then(res => res.data);
    }
);
