import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDERsCategoriesAction } from 'modules/newLoad';
import { DERsCategoriesHFetchedSelector, DERsCategoriesSplitBySectionHashSelector } from 'modules/newLoad/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  show: boolean;
  name: string;
  value: number | null;
  setFieldValue: (field: string, value: number | null) => void;
}

const SelectDERsCategory: React.FC<Props> = ({ show, name, value = null, setFieldValue, errorKey }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const DERsCategoriesHFetched = useSelector(DERsCategoriesHFetchedSelector);
  const DERsCategoriesSplitBySectionHash = useSelector(DERsCategoriesSplitBySectionHashSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [sectionValue, setSectionValue] = useState<Type.SelectOption | null>(null);

  useEffect(() => {
    if (DERsCategoriesHFetched) return;
    setLoading(true);
    dispatch(fetchDERsCategoriesAction()).finally(() => setLoading(false));
  }, [dispatch, DERsCategoriesHFetched]);

  const sectionOptions = useMemo(
    () => Object.keys(DERsCategoriesSplitBySectionHash || {}).map((key: string) => ({ value: key, label: key })),
    [DERsCategoriesSplitBySectionHash]
  );

  const { options, selectValue } = useMemo(() => {
    if (!sectionValue && !value) return { options: [], selectValue: null };
    return Object.values(DERsCategoriesSplitBySectionHash || {})
      .flat()
      .reduce(
        (acc: { options: Type.SelectOption[]; selectValue: Type.SelectOption | null }, i) => {
          if ((sectionValue && i.section !== sectionValue.value) || !i.category) return acc;
          const item = { value: i.id, label: i.category };
          if (value && item.value === value) {
            if (!sectionValue) setSectionValue({ value: i.section, label: i.section });
            acc.selectValue = { value: i.id, label: i.category };
          }
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      );
  }, [sectionValue, value, DERsCategoriesSplitBySectionHash]);

  const handleSectionSelectChange = useCallback(
    (value: Type.SelectOption) => {
      setFieldValue(name, null);
      setSectionValue(value);
    },
    [name, setFieldValue]
  );

  const handleCategorySelectChange = useCallback(
    (value: Type.SelectOption) => setFieldValue(name, value.value as number),
    [name, setFieldValue]
  );

  useEffect(() => {
    // Automatically apply first category on section changed
    if (show && !value && options[0]?.value) setFieldValue(name, options[0]?.value as any);
  }, [show, name, options, value, sectionValue, setFieldValue]);

  return (
    <>
      <Form.Group>
        <FormReactSelect
          labelKey="Section"
          value={sectionValue}
          options={sectionOptions}
          onChange={handleSectionSelectChange}
          isDisabled={!DERsCategoriesHFetched}
          isLoading={loading}
          variant="small"
        />
      </Form.Group>
      <Form.Group>
        <FormReactSelect
          labelKey="Category"
          value={selectValue}
          options={options}
          onChange={handleCategorySelectChange}
          isDisabled={!DERsCategoriesHFetched || !sectionValue}
          isLoading={loading}
          errorKey={errorKey}
          variant="small"
        />
      </Form.Group>
    </>
  );
};

export default SelectDERsCategory;
