import React, { useCallback, useMemo } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { IDsState } from './ViewAnalysisTool';

interface Props extends Omit<FormReactSelectProps, 'value'> {
  id: string;
  value: number | null;
  setIds: React.Dispatch<React.SetStateAction<IDsState[]>>;
}

const SelectAnalysisToolSimulation: React.FC<Props> = ({ id, value, options, setIds, ...props }) => {
  const selectValue = useMemo(() => options?.find(option => option.value === value) || null, [options, value]);

  const handleSelectChange = useCallback(
    (option: Options.SimulationOption<number>): void => {
      setIds(prev =>
        prev.map(item => (item.id === id ? { ...item, simulationId: option.value, versionId: option.versionId } : item))
      );
    },
    [id, setIds]
  );

  return (
    <FormReactSelect
      isLoading={!options}
      isDisabled={!options || !options?.length}
      value={selectValue}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      blurInputOnSelect
      variant="small"
      mutedTextLabelKey="Simulation"
      {...props}
    />
  );
};

export default SelectAnalysisToolSimulation;
