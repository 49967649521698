import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.history;

export const historyCountSelector = createSelector(
  rootSelector,
  (history: History.Root): History.Root['historyCount'] => history.historyCount
);

export const historyItemsSelector = createSelector(
  rootSelector,
  (history: History.Root): History.Root['historyItems'] => history.historyItems
);

export const checklistsCountSelector = createSelector(
  rootSelector,
  (history: History.Root): History.Root['checklistsCount'] => history.checklistsCount
);

const checklistsHashSelector = createSelector(
  rootSelector,
  (history: History.Root): History.Root['checklistsHash'] => history.checklistsHash
);

export const checklistsSelector = createSelector(checklistsHashSelector, (hash): History.Checklist[] =>
  Object.values(hash)
);

export const historyChecklistSelectorFactory = (id: number | null) =>
  createSelector(checklistsHashSelector, (hash): History.Checklist | undefined => hash[`_${id}_`]);
