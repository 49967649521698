import Highcharts from 'highcharts';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import Chart from 'components/_charts/Chart';
import { fetchSummaryPlanAction } from 'modules/investment';
import {
  portfolioOptionsSelector,
  scenarioOptionsHashSelector,
  simulationOptionsHashSelector,
} from 'modules/options/selectors';
import { selectedCurrencySelector } from 'modules/layouts/selectors';
import { CurrenciesRatesMap } from 'constants/index';
import { IDsState } from './ViewAnalysisTool';

type Hash = Record<string, { name: string; data: Investment.Root['summaryPlanItems'] }> | null;

interface ChartDataProps {
  series: Omit<Shared.SeriesOptionsType, 'type'>[];
  categories: number[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

interface Props {
  isInvestments: boolean;
  ids: IDsState[];
  setLoading: React.Dispatch<boolean>;
}

const ChartInvestmentSummary: React.FC<Props> = ({ isInvestments, ids, setLoading }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, numberFormat } = useLocale();

  const selectedCurrency = useSelector(selectedCurrencySelector);
  const [summaryPlanCurrency, setSummaryPlanCurrency] = useState<string>('');
  const currency = selectedCurrency || summaryPlanCurrency;
  const rate = selectedCurrency ? CurrenciesRatesMap[currency] || 1 : 1;

  const [itemsHashStore, setItemsHashStore] = useState<Hash>(null);
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  const portfolioOptions = useSelector(portfolioOptionsSelector);
  const scenarioOptionsHash = useSelector(scenarioOptionsHashSelector);
  const simulationOptionsHash = useSelector(simulationOptionsHashSelector);

  const getSeriesName = useCallback(
    ({ portfolioId, scenarioId, simulationId, versionId }: Omit<IDsState, 'id' | 'flex'>) => {
      const portfolioOptionLabel = portfolioOptions!.find(option => option.value === portfolioId)?.label;
      const scenarioOptionLabel = scenarioOptionsHash?.[portfolioId!]!.find(
        option => option.value === scenarioId
      )?.label;
      const simulationOptionLabel = simulationOptionsHash?.[`${portfolioId}_${scenarioId}`]!.find(
        option => option.value === simulationId && option.versionId === versionId
      )?.label;
      return `<div>${portfolioOptionLabel} - ${scenarioOptionLabel}</div><small>${simulationOptionLabel ? ` - ${simulationOptionLabel}` : ''}</small>`;
    },
    [portfolioOptions, scenarioOptionsHash, simulationOptionsHash]
  );

  const getStoreKey = useCallback(
    ({ portfolioId, scenarioId, flex, simulationId, versionId }: Omit<IDsState, 'id'>) =>
      `${portfolioId}_${scenarioId}_${flex}_${simulationId}_${versionId}`,
    []
  );

  const seriesItems = useMemo(() => {
    const items = ids.map(item => itemsHashStore?.[getStoreKey(item)]).filter(Boolean);
    return items.length === ids.length ? items : null;
  }, [ids, itemsHashStore, getStoreKey]);

  const emptyDataIds = useMemo(
    () => ids.filter(item => !itemsHashStore?.[getStoreKey(item)]),
    [ids, itemsHashStore, getStoreKey]
  );

  useEffect(() => {
    if (!isInvestments || !emptyDataIds.length) return;
    setLoading(true);
    Promise.all(
      emptyDataIds.map(({ portfolioId, scenarioId, flex, simulationId, versionId }) => {
        return dispatch(
          fetchSummaryPlanAction({
            portfolioId,
            scenarioId,
            simulationId,
            versionId,
            skipStoreUpdate: true,
            inputFilters: { startYear: new Date().getFullYear(), endYear: 2100, flex },
          })
        ).then(action => {
          return {
            [getStoreKey({ portfolioId, scenarioId, flex, simulationId, versionId })]: {
              name: getSeriesName({ portfolioId, scenarioId, simulationId, versionId }),
              data: action.payload.summaryPlanItems || [],
            },
            currency: action.payload.summaryPlanCurrency,
          };
        });
      })
    )
      .then(results => {
        setItemsHashStore(prev => ({ ...prev, ...results.reduce((acc, item) => ({ ...acc, ...item })) }));
        if (results?.[0]?.currency) setSummaryPlanCurrency(results[0].currency);
      })
      .finally(() => setLoading(false));
  }, [isInvestments, emptyDataIds, dispatch, getSeriesName, getStoreKey, setLoading]);

  useEffect(() => {
    if (!isInvestments || !seriesItems) return;
    const items = seriesItems.find(elem => elem?.data.length)?.data || [];
    const categories = [...new Set(items.map(item => item.suggested_replacement_year))];

    const series = seriesItems.map(item => ({
      name: item?.name,
      data: item?.data.map(item => item.replacement_cost / rate),
    }));

    setChartData({
      series,
      categories,
      title: 'Yearly summary',
      yAxisTitle: 'Investment cost ({{currency}})',
      xAxisTitle: 'Investment year',
    });
  }, [isInvestments, seriesItems, rate]);

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: { type: 'column', zoomType: 'y' },
      title: {
        text: getIntl(chartData?.title || ''),
      },
      xAxis: {
        title: {
          text: getIntl(chartData?.xAxisTitle || ''),
        },
        categories: chartData?.categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: getIntl(chartData?.yAxisTitle || '', { currency }),
        },
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return [
            `<b>${this.series.name}</b><br/>`,
            `${getIntl(chartData?.xAxisTitle || '')}: ${this.x}<br/>`,
            `${getIntl(chartData?.yAxisTitle || '', { currency })}: ${numberFormat(this.y, {
              minimumFractionDigits: 2,
            })}`,
          ].join('');
        },
      },
      series: chartData?.series,
      legend: { useHTML: true },
    }),
    [chartData, currency, getIntl, numberFormat]
  ) as Highcharts.Options;

  if (!isInvestments) return null;
  return (
    <Chart
      options={chartData ? options : null}
      dataMarker="chart_analysis_tool_investment"
      height="calc(100vh - 443px)"
    />
  );
};

export default ChartInvestmentSummary;
