import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';

export const fetchTaskMaterialsAction: any = createAction(
  'tasks/FETCH_MATERIALS',
  async (
    {
      skipPagination,
      skipStoreUpdate,
    }: {
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
    } = { skipPagination: false, skipStoreUpdate: undefined } as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'materialsCount' | 'materialsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.TASKS_MATERIALS)(state);
      return axios
        .get('api/admin/v1/secure/materials', {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? null : offset,
            sort,
            column,
            query: skipPagination ? null : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          materialsCount: res.data.count,
          materialsHash: _keyBy(res.data.rows, (item: Tasks.Material) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);

export const createTaskMaterialAction: any = createAction('tasks/CREATE_MATERIAL', async (data: Tasks.Material) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.post('api/admin/v1/secure/materials', data).then(async () => {
      await dispatch(fetchTaskMaterialsAction());
      dispatch(setSuccessToastAction('Material has been created'));
    });
});

export const editTaskMaterialAction: any = createAction(
  'tasks/EDIT_MATERIAL',
  async (id: number, data: Type.TaskChecklistActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios.put(`api/admin/v1/secure/materials/${id}`, data).then(async () => {
        await dispatch(fetchTaskMaterialsAction());
        dispatch(setSuccessToastAction('Material has been saved'));
      });
  }
);

export const deleteTaskMaterialAction: any = createAction('tasks/DELETE_MATERIAL', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.delete(`api/admin/v1/secure/materials/${id}`).then(async () => {
      await dispatch(fetchTaskMaterialsAction());
      dispatch(setSuccessToastAction('Material has been deleted'));
    });
});

export const fetchMaterialsByTaskIDAction: any = createAction(
  'tasks/FETCH_TASK_MATERIALS_BY_TASK_ID',
  async (id: number, type: Type.PaginationType) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'taskMaterialsCount' | 'taskMaterials'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(type)(state);
      return axios
        .get(`api/admin/v2/secure/tasks/${id}/materials`, {
          params: { limit, offset, sort, column, query },
        })
        .then((res: any) => ({
          taskMaterialsCount: res.data.count,
          taskMaterials: res.data.rows,
        }));
    }
);
