import styled from 'styled-components';
import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { push, goBack } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, generatePath } from 'react-router';
import { useLocale } from 'hooks';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { fetchTaskDetailsAction, deleteTaskAction, setTaskDetailsAction } from 'modules/tasks';
import { taskDetailsSelector } from 'modules/tasks/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import { isMaterialsEnabledSelector } from 'modules/app/selectors';
import { TaskDetailsMeta, TaskDeleteAlert } from 'components';
import { TasksModal } from 'components/_modals';
import { Tabs, Tab, Button, BackToTableButton, DataTableTemplate, Gallery, Spinner } from 'components/_common';
import TaskDetailsComments from './TaskDetailsComments';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';
import TaskDetailsUserPhotos from './TaskDetailsUserPhotos';
import TaskDetailsChecklist from './TaskDetailsChecklist';
import TaskDetailsInfo from './TaskDetailsInfo';
import TableTaskMaterials from './TableTaskMaterials';
import { isNKTenant } from 'utils';
import { Routes } from 'constants/index';

const TaskDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match, history }) => {
  const id: number = Number(match.params.id);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const task = useSelector(taskDetailsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);
  const isMaterialsEnabled = useSelector(isMaterialsEnabledSelector);

  useEffect(() => {
    if (!portfolioId || !versionId) return;
    // fetch task details and redirect to table in case of network error
    dispatch(fetchTaskDetailsAction({ id, portfolioId, versionId })).catch(() =>
      dispatch(push(generatePath(Routes.Tasks)))
    );
    // clean task details on view unmount
    return () => {
      dispatch(setTaskDetailsAction(null));
    };
  }, [id, portfolioId, versionId, dispatch]);

  const handleDeleteBtnClick = useCallback(() => {
    dispatch(
      setModalConfirmationMetaAction({
        bodyComponent: <TaskDeleteAlert name={task?.name || ''} />,
        onConfirm: () => dispatch(deleteTaskAction(id)).then(() => dispatch(push(generatePath(Routes.Tasks)))),
      })
    );
  }, [dispatch, id, task]);

  const handleEditBtnClick = useCallback(() => dispatch(setLayoutAction({ taskModalMeta: { id } })), [dispatch, id]);

  const { length } = history;
  const handleBackBtnClick = useCallback(
    (event: React.MouseEvent) => {
      if (length <= 2) return;
      event.preventDefault();
      dispatch(goBack());
    },
    [length, dispatch]
  );

  return (
    <>
      <DataTableTemplate title={`${getIntl('Task')} / ${id}`} paddingBottom>
        <div className="d-flex justify-content-between">
          <Link to={generatePath(Routes.Tasks)} onClick={handleBackBtnClick}>
            <BackToTableButton />
          </Link>
          <div>
            <Button
              className="mr-2"
              icon={<IconEdit />}
              data-id={id}
              marginLeft
              onClick={handleEditBtnClick}
              aria-label="Edit task"
            />
            <Button icon={<IconDelete />} onClick={handleDeleteBtnClick} />
          </div>
        </div>
        <StyledContainer>
          <div>
            {!task ? (
              <Spinner isInFullHeightContainer />
            ) : (
              <>
                <Row className="justify-content-md-between mb-5">
                  <Col md={6}>
                    <TaskDetailsChecklist id={task.id} status={task.status} />

                    <TaskDetailsInfo className="mt-5" task={task} />
                  </Col>
                  <Col md={5}>
                    <TaskDetailsMeta task={task} />
                  </Col>
                </Row>
                <Tabs defaultActiveKey="comments" mountOnEnter>
                  <Tab eventKey="comments" title={getIntl('Comments')} className="mt-4">
                    <TaskDetailsComments taskId={id} />
                  </Tab>
                  {(isMaterialsEnabled || isNKTenant) && (
                    <Tab eventKey="materials" title={getIntl('Materials')} className="mt-4">
                      <TableTaskMaterials id={id} />
                    </Tab>
                  )}
                  <Tab
                    eventKey="photo"
                    title={`${getIntl('Answers photos')} (${task?.images.length})`}
                    className="mt-4"
                  >
                    <Gallery images={task?.images} />
                  </Tab>

                  <Tab
                    eventKey="users_photo"
                    title={`${getIntl('Users photos')} (${task.usersAttachments.length})`}
                    className="mt-4"
                  >
                    <TaskDetailsUserPhotos {...task} />
                  </Tab>
                </Tabs>
              </>
            )}
          </div>
        </StyledContainer>
      </DataTableTemplate>
      <TasksModal hideButton />
    </>
  );
};

const StyledContainer = styled.div`
  ${props => `
    padding: ${props.theme.paddings.primary};
    background: ${props.theme.colors.grey25};
    border-top: 1px solid ${props.theme.colors.grey100};
    display: flex;
    flex-grow: 1;

    > div {
      background: ${props.theme.colors.white};
      padding: ${props.theme.paddings.primary};
      border: 1px solid ${props.theme.colors.grey100};
      flex-grow: 1;
    }`}
`;

export default TaskDetails;
