import React from 'react';
import { fetchHistoryChecklistAction, updateHistoryChecklistAction } from 'modules/history';
import { Checklist } from 'components/_common';

interface Props {
  id: number;
}

const HistoryDetailsChecklist: React.FC<Props> = ({ id }) => (
  <Checklist<History.ChecklistAnswer>
    isHistoryChecklist
    id={id}
    fetchChecklistAction={fetchHistoryChecklistAction}
    updateChecklistAction={updateHistoryChecklistAction}
  />
);

export default HistoryDetailsChecklist;
