import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { fetchInstallationNumberAutoCompleteAction } from 'modules/assets';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  values: string[];
}

const SelectInstallationNumbers: React.FC<Props> = ({ values, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Type.SelectOption[] | null>(null);

  const selectValues = useMemo(() => values.map((value: string) => ({ label: value, value })), [values]);

  const debounced = useDebouncedCallback((query: string) => {
    setIsLoading(true);
    dispatch(fetchInstallationNumberAutoCompleteAction(query))
      .then((action: Shared.ReduxAction<Asset.TaskAssetCodeItem[]>) => {
        setOptions(action.payload.map(({ assetCode }) => ({ value: assetCode, label: assetCode })));
      })
      .finally(() => setIsLoading(false));
  }, 1000);

  useEffect(() => debounced.cancel, [debounced.cancel]);

  const handleInputChange = useCallback(
    (newValue: string) => {
      setInputValue(newValue);
      if (newValue.length < 3) {
        setOptions(null);
        setIsLoading(false);
        debounced.cancel();
      } else {
        debounced(newValue);
      }
    },
    [debounced]
  );

  return (
    <FormReactSelect
      {...props}
      value={selectValues}
      options={options || []}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      isLoading={isLoading}
      placeholderKey="Type to search"
      menuIsOpen={Boolean(options)}
      label="Installation numbers"
      isMulti
      isClearable
      variant="small"
    />
  );
};

export default SelectInstallationNumbers;
