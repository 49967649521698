import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';

export const fetchTaskChecklistAction: any = createAction('tasks/FETCH_TASK_CHECKLIST', async (taskId: number) => {
  return (): Promise<Type.Hash<Tasks.ChecklistAnswer>> =>
    axios
      .get(`/api/admin/v2/secure/tasks/checkListAnswers/${taskId}`)
      .then(res => _keyBy(res.data.questions, 'taskCheckListQuestionId'));
});

export const updateTaskChecklistAction: any = createAction(
  'tasks/UPDATE_TASK_CHECKLIST',
  (taskId: number, checklist: Tasks.ChecklistAnswer[]) => {
    return (): Promise<void> => axios.put(`/api/admin/v2/secure/tasks/checkListAnswers/${taskId}`, checklist);
  }
);

export const fetchTaskChecklistsAction: any = createAction(
  'tasks/FETCH_CHECKLISTS',
  async (
    {
      appAction,
      assetCategoryCode,
      skipPagination,
      skipStoreUpdate,
    }: {
      appAction: Type.TaskAppActions;
      assetCategoryCode: Type.AssetCategories;
      skipPagination: boolean;
      skipStoreUpdate: true | undefined;
    } = { skipPagination: false, skipStoreUpdate: undefined } as any
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'checklistsCount' | 'checklistsHash'>> => {
      const shouldSkipPagination = skipPagination || Boolean(appAction && assetCategoryCode);
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.TASKS_CHECKLISTS)(state);
      return axios
        .get('api/admin/v2/secure/tasks/checkLists', {
          params: {
            limit: shouldSkipPagination ? null : limit,
            offset: shouldSkipPagination ? null : offset,
            sort,
            column,
            query: shouldSkipPagination ? null : query,
            lang: appLangSelector(state).toLowerCase(),
            appAction,
            assetCategoryCode,
          },
        })
        .then((res: any) => ({
          checklistsCount: res.data.count,
          checklistsHash: _keyBy(res.data.rows, (item: Tasks.Checklist) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);

export const createTaskChecklistAction: any = createAction(
  'tasks/CREATE_CHECKLIST',
  async (data: Type.TaskChecklistActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios
        .post(`api/admin/v2/secure/tasks/checkLists`, { ...data, assetCategoryCode: data.assetCategoryCode || '' })
        .then(async () => {
          await dispatch(fetchTaskChecklistsAction());
          dispatch(setSuccessToastAction('Checklist has been created'));
        });
  }
);

export const editTaskChecklistAction: any = createAction(
  'tasks/EDIT_CHECKLIST',
  async (id: number, data: Type.TaskChecklistActionProps) => {
    return (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios
        .put(`api/admin/v2/secure/tasks/checkLists/${id}`, { ...data, assetCategoryCode: data.assetCategoryCode || '' })
        .then(async () => {
          await dispatch(fetchTaskChecklistsAction());
          dispatch(setSuccessToastAction('Checklist has been saved'));
        });
  }
);

export const deleteTaskChecklistAction: any = createAction('tasks/DELETE_CHECKLIST', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.delete(`api/admin/v2/secure/tasks/checkLists/${id}`).then(async res => {
      await dispatch(fetchTaskChecklistsAction());
      dispatch(setSuccessToastAction('Checklist has been deleted'));
    });
});
