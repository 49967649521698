import { createSelector } from 'reselect';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  selectedCurrencySelector,
} from 'modules/layouts/selectors';
import { CurrenciesRatesMap, PaginationType } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.investment;

export const summaryPlanItemsSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['summaryPlanItems'] => investment.summaryPlanItems
);

export const summaryPlanPaginatedItemsSelector = createSelector(
  [rootSelector, paginationSelectorFactory(PaginationType.SUMMARY_PLAN)],
  (investment: Investment.Root, { limit, offset }): Investment.Root['summaryPlanItems'] =>
    investment.summaryPlanItems.slice(offset, offset + limit)
);

export const summaryPlanCountSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): number => investment.summaryPlanCount
);

export const summaryPlanCurrencySelector = createSelector(
  [selectedCurrencySelector, rootSelector],
  (selectedCurrency, investment): { currency: Layouts.Currencies; rate: number } => {
    const currency = selectedCurrency || investment.summaryPlanCurrency || ('' as Layouts.Currencies);
    const rate = selectedCurrency ? CurrenciesRatesMap[currency] || 1 : 1;
    return { currency, rate };
  }
);

export const detailedPlanItemsSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['detailedPlanItems'] => investment.detailedPlanItems
);

export const detailedPlanCountSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): number => investment.detailedPlanCount
);

export const detailedPlanCurrencySelector = createSelector(
  [selectedCurrencySelector, rootSelector],
  (selectedCurrency, investment): { currency: Layouts.Currencies; rate: number } => {
    const currency = selectedCurrency || investment.detailedPlanCurrency || ('' as Layouts.Currencies);
    const rate = selectedCurrency ? CurrenciesRatesMap[currency] || 1 : 1;
    return { currency, rate };
  }
);

export const summaryPlanGroupOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.summaryPlanGroupOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const assetCategoriesOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.assetCategoriesOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const replacementReasonOptionsHashSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['replacementReasonOptionsHash'] =>
    investment.replacementReasonOptionsHash
);

export const replacementReasonOptionsSelector = createSelector(
  [replacementReasonOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (hash, portfolioId, scenarioId): Type.SelectOption[] | null => hash[`${portfolioId}_${scenarioId}`] || null
);

export const gridZonesOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.gridZonesOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const voltageLevelsOptionsHashSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['voltageLevelsOptionsHash'] => investment.voltageLevelsOptionsHash
);

export const voltageLevelsOptionsSelector = createSelector(
  [voltageLevelsOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (
    voltageLevelsOptionsHash: Investment.Root['voltageLevelsOptionsHash'],
    portfolioId,
    scenarioId
  ): Type.SelectOption[] | null => voltageLevelsOptionsHash[`${portfolioId}_${scenarioId}`] || null
);
